import { ChangeEventHandler, Dispatch, SetStateAction, useMemo, useState } from 'react';
import { DateRangeFormat } from './DateRange';
import { MAX_DATE_RANGE } from 'constants/general';
import { autoAddSeparator, shouldRemoveSeparator } from 'utils/formatDate';
import { errorColor, font, primaryMerBlue, spaceL, spaceS, spaceXxs } from 'styles/variables';
import { useCountrySpecificContent } from 'hooks/useCountrySpecificContent';
import { useTranslation } from 'react-i18next';
import DeleteIcon from 'assets/icons/delete.svg?react';
import IconButton from 'components/clickables/IconButton';
import TextInput from './TextInput';
import styled from 'styled-components';
import useDateValidation from 'hooks/useDateValidation';

const StyledTextInput = styled(TextInput)`
    .adornment {
        top: 26px;
        padding-right: ${spaceXxs};
    }
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 ${spaceL};
`;

const StyledIconButton = styled(IconButton)`
    color: ${primaryMerBlue};
    border-radius: 100%;
    &.error {
        color: ${errorColor};
    }
`;

const RangeErrorText = styled.p`
    color: ${errorColor};
    grid-column: span 2;
    margin-top: -${spaceS};
    font-size: ${font.size.s};
`;

type DateRangeFieldsProps = {
    range: DateRangeFormat;
    setRange: Dispatch<SetStateAction<DateRangeFormat>>;
    hideOptionalText?: boolean;
};

function DateRangeFields({ range, setRange, hideOptionalText }: DateRangeFieldsProps): JSX.Element {
    const { t } = useTranslation();
    const [rangeErrorText, setRangeErrorText] = useState<string>();

    const { isDateAfterToday, isFromDateAfterToDate, isValidDate, isValidDateRange } = useDateValidation();
    const { locale, dateDelimiterRegExp, datePlaceholder } = useCountrySpecificContent();

    const fromError = useMemo(() => {
        if (range.from) {
            if (!isValidDate(range.from)) {
                return {
                    type: 'match',
                    message: t('form.dateRange.invalidDate'),
                };
            }
            if (isDateAfterToday(range.from)) {
                return {
                    type: 'match',
                    message: t('form.dateRange.dateAfterToday'),
                };
            }
        }
        return undefined;
    }, [range.from, t, isDateAfterToday, isValidDate]);

    const toError = useMemo(() => {
        if (range.to) {
            if (!isValidDate(range.to)) {
                return {
                    type: 'match',
                    message: t('form.dateRange.invalidDate'),
                };
            }
            if (isDateAfterToday(range.to)) {
                return {
                    type: 'match',
                    message: t('form.dateRange.dateAfterToday'),
                };
            }
        }
        return undefined;
    }, [range.to, t, isDateAfterToday, isValidDate]);

    const rangeError = useMemo(() => {
        if (!fromError && !toError) {
            if (range.from.length === 10 && range.to.length === 10) {
                if (isFromDateAfterToDate(range.from, range.to)) {
                    setRangeErrorText(t('form.dateRange.invalidRange'));
                    return {
                        type: 'match',
                        message: ' ',
                    };
                }
                if (!isValidDateRange(range.from, range.to)) {
                    setRangeErrorText(
                        t('form.dateRange.tooManyDaysInRange', {
                            maxDays: MAX_DATE_RANGE,
                        }),
                    );
                    return {
                        type: 'match',
                        message: ' ',
                    };
                }
            } else if ((range.from && !range.to) || (!range.from && range.to)) {
                setRangeErrorText(t('form.dateRange.invalidRange'));
                return {
                    type: 'match',
                    message: ' ',
                };
            }
        }
        setRangeErrorText(undefined);
        return undefined;
    }, [fromError, toError, range.from, range.to, isFromDateAfterToDate, isValidDateRange, t]);

    const clearFromValue = (): void => {
        setRange({ from: '', to: range.to });
    };

    const clearToValue = (): void => {
        setRange({ from: range.from, to: '' });
    };

    const handleFromInputChange: ChangeEventHandler<HTMLInputElement> = (e): void => {
        const inputValue = e.currentTarget.value.replace(dateDelimiterRegExp, '');
        const dateString = autoAddSeparator(inputValue, locale);
        setRange({ from: dateString, to: range.to });
    };

    const handleToInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const inputValue = e.currentTarget.value.replace(dateDelimiterRegExp, '');
        const dateString = autoAddSeparator(inputValue, locale);
        setRange({ from: range.from, to: dateString });
    };

    const handleKeyPressFunc = (e: {
        which: number;
        currentTarget: { value: string };
        preventDefault: () => void;
    }): void => {
        if (e.which === 8) {
            if (shouldRemoveSeparator(e.currentTarget.value, locale)) {
                e.currentTarget.value = e.currentTarget.value.slice(0, e.currentTarget.value.length - 1);
            }
        } else if (e.which === 189 || e.which === 173 || e.which === 190) {
            // prevent user from adding "-" and "."
            // character "dash" has key code 173 in Firefox and 189 in other browsers
            // character "." has key code 190
            e.preventDefault();
        }
    };

    return (
        <Container>
            <StyledTextInput
                dataTestId="dateFrom"
                name="dateFrom"
                label={t('form.dateRange.from')}
                placeholder={datePlaceholder}
                maxLength={10}
                value={range.from}
                onChange={handleFromInputChange}
                onKeyDown={handleKeyPressFunc}
                fieldError={fromError ?? rangeError}
                hideOptionalText={hideOptionalText}
                adornment={
                    range.from && (
                        <StyledIconButton
                            data-testid="clearFromDateButton"
                            onClick={clearFromValue}
                            className={(fromError || rangeError) && 'error'}
                        >
                            <DeleteIcon />
                        </StyledIconButton>
                    )
                }
            />
            <StyledTextInput
                dataTestId="dateTo"
                name="dateTo"
                label={t('form.dateRange.to')}
                placeholder={datePlaceholder}
                maxLength={10}
                value={range.to}
                onChange={handleToInputChange}
                onKeyDown={handleKeyPressFunc}
                fieldError={toError ?? rangeError}
                hideOptionalText={hideOptionalText}
                adornment={
                    range.to && (
                        <StyledIconButton
                            data-testid="clearToDateButton"
                            onClick={clearToValue}
                            className={(toError || rangeError) && 'error'}
                        >
                            <DeleteIcon />
                        </StyledIconButton>
                    )
                }
            />
            <RangeErrorText data-testid="dateRangeError">{rangeErrorText}</RangeErrorText>
        </Container>
    );
}

export default DateRangeFields;
