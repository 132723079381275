import { ContentContainer, Container as PageContainer } from 'components/layout/PageLayoutWithoutMenu';
import { Container as HeaderContainer } from 'components/layout/Header';
import { font, lighterGrey, screenWidthSmall, spaceL, spaceM, spaceS, spaceXl } from 'styles/variables';
import { useTranslation } from 'react-i18next';
import LanguageSelector from 'components/forms/LanguageSelector';
import LogoutButton from 'auth/LogoutButton';
import MerLogo from 'components/visuals/MerLogo';
import illustration from 'assets/images/Mer-EV-Charging.webp';
import styled from 'styled-components';

const StyledHeaderContainer = styled(HeaderContainer)`
    .dropdown {
        .select-dropdown {
            right: 0;
            left: auto;
        }
    }
`;

const Container = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: ${spaceXl};
    padding: ${spaceM};
    background: ${lighterGrey};

    img {
        width: inherit;
    }
    @media screen and (max-width: ${screenWidthSmall}) {
        grid-template-columns: 1fr;
        gap: 0;
        padding: ${spaceM};
    }
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: ${spaceS};
    font-size: ${font.size.s};

    p.bold {
        font-weight: ${font.weight.semiBold};
    }
`;

const Header = styled.h1`
    font-size: ${font.size.xxl};
    font-weight: ${font.weight.bold};
    margin-bottom: ${spaceL};
`;

const DivFlexRight = styled.div`
    margin-top: ${spaceL};
    display: flex;
    justify-content: right;
`;

function PageWithoutRole(): JSX.Element {
    const { t } = useTranslation();
    return (
        <PageContainer data-testid="pageForUserWithoutRole">
            <StyledHeaderContainer>
                <MerLogo size="64px" />
                <LanguageSelector />
            </StyledHeaderContainer>
            <ContentContainer>
                <Container>
                    <img src={illustration} alt={t('pageWithoutRole.heroImageDescriptionText')} />
                    <ContentWrapper>
                        <Header>{t('pageWithoutRole.title')}</Header>
                        <p>{t('pageWithoutRole.p1')}</p>
                        <p>{t('pageWithoutRole.p2')}</p>
                        <p className="bold">{t('pageWithoutRole.p3')}</p>
                    </ContentWrapper>
                </Container>
                <DivFlexRight>
                    <LogoutButton />
                </DivFlexRight>
            </ContentContainer>
        </PageContainer>
    );
}

export default PageWithoutRole;
