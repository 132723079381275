import ApiError from 'classes/ApiError';

export type useBusinessIdResult = {
    activeParentBusinessId: string;
    error?: ApiError;
    isLoading: boolean;
};

const useBusinessId = (): useBusinessIdResult => {
    const activeParentBusinessId = localStorage.getItem('activeParentBusinessId') ?? '';
    return {
        activeParentBusinessId,
        error: undefined,
        isLoading: false,
    };
};

export default useBusinessId;
