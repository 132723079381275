import { CreateSubscriptionOrderType, SubscriptionOrder, SubscriptionOrderSubmitType } from 'types/subscription';
import { UseMutationResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ApiError from 'classes/ApiError';
import useApi from 'hooks/useApi';

export type useSubscriptionOrderResponseType = {
    subscriptionOrder?: SubscriptionOrder;
    error?: ApiError;
    isLoading: boolean;
    isInitialLoading?: boolean;
    patchSubscriptionOrder: UseMutationResult<SubscriptionOrder, ApiError, Partial<SubscriptionOrder>>;
    createSubscriptions: UseMutationResult<SubscriptionOrder, ApiError, CreateSubscriptionOrderType>;
};

type useSubscriptionPropType = {
    businessId?: string;
    orderId?: string;
};

const useSubscriptionOrder = ({ businessId, orderId }: useSubscriptionPropType): useSubscriptionOrderResponseType => {
    const { get, patch } = useApi<SubscriptionOrder, SubscriptionOrderSubmitType, Partial<SubscriptionOrder>>();
    const { post } = useApi<SubscriptionOrder, CreateSubscriptionOrderType>();
    const url = `/v1/businesses/${businessId}/subscription-orders/${orderId}`;
    const fetcher: () => Promise<SubscriptionOrder> = () => get(url);
    const queryClient = useQueryClient();
    const {
        data: subscription,
        error,
        isFetching: isLoading,
        isLoading: isInitialLoading,
    } = useQuery<SubscriptionOrder, ApiError>({
        queryKey: [url],
        queryFn: fetcher,
        enabled: !!businessId && !!orderId,
    });
    const patchSubscriptionOrder = useMutation<SubscriptionOrder, ApiError, Partial<SubscriptionOrder>>({
        mutationFn: (patchSubscriptionOrderData: Partial<SubscriptionOrder>) => patch(url, patchSubscriptionOrderData),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: [url] }),
    });

    const createSubscriptions = useMutation<SubscriptionOrder, ApiError, CreateSubscriptionOrderType>({
        mutationFn: (subscriptionsOrder: CreateSubscriptionOrderType) =>
            post(`/v1/businesses/${businessId}/subscription-orders`, subscriptionsOrder),
    });

    return {
        subscriptionOrder: subscription,
        error: error ?? undefined,
        isLoading: isLoading || createSubscriptions.isPending,
        createSubscriptions,
        patchSubscriptionOrder,
        isInitialLoading,
    };
};

export default useSubscriptionOrder;
