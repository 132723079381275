import { borderRadiusM, contentBoxPadding, font, lighterGrey, spaceM, spaceXs } from 'styles/variables';
import { styled } from 'styled-components';

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: ${lighterGrey};
    min-height: 16.25rem;
    border-radius: ${borderRadiusM};
    overflow: hidden;

    h3 {
        font-size: ${font.size.subtitle};
        line-height: ${font.lineHeight.l};
        margin-bottom: ${spaceXs};
    }
    p {
        line-height: ${font.lineHeight.l};
        margin-bottom: ${spaceM};
    }
`;

const Content = styled.div`
    padding: ${contentBoxPadding};
`;
const Image = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: left;
`;

type LargeContentBoxProps = {
    title: string;
    text: string;
    image: string;
    children: React.ReactNode;
};

function LargeContentBox({ title, text, image, children }: LargeContentBoxProps): JSX.Element {
    return (
        <Container>
            <Content>
                <h3>{title}</h3>
                <p>{text}</p>
                {children}
            </Content>
            <Image src={image} />
        </Container>
    );
}

export default LargeContentBox;
