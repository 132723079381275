import { Divider } from './Divider';
import { font, spaceM } from 'styles/variables';
import { t } from 'i18next';
import styled from 'styled-components';

const Title = styled.h2`
    font-size: ${font.size.l};
    margin-bottom: ${spaceM};
`;

type EditDetailsSectionProps = {
    children: React.ReactNode;
};

export function EditDetailsSection({ children }: EditDetailsSectionProps): JSX.Element {
    return (
        <section>
            <Divider />
            <Title>{t('form.editDetails')}</Title>
            {children}
        </section>
    );
}
