import { IBulkSubscriptions, SubscriptionBulkResult } from 'types/subscription';
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { queryClient } from 'App';
import ApiError from 'classes/ApiError';
import useApi from './useApi';

export type useSubscriptionBulkResponseType = {
    error?: ApiError;
    isLoading: boolean;
    createSubscriptions: UseMutationResult<SubscriptionBulkResult, ApiError, IBulkSubscriptions>;
};

const useSubscriptionBulk = (): useSubscriptionBulkResponseType => {
    const { post } = useApi<SubscriptionBulkResult, IBulkSubscriptions>();
    const url = '/v1/subscription-orders:create-in-bulk';

    const createSubscriptions = useMutation<SubscriptionBulkResult, ApiError, IBulkSubscriptions>({
        mutationFn: (subscriptionOrders: IBulkSubscriptions) => post(url, subscriptionOrders),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: [url] }),
    });

    return {
        error: createSubscriptions.error ?? undefined,
        isLoading: createSubscriptions.isPending,
        createSubscriptions,
    };
};

export default useSubscriptionBulk;
