import { BulkSubscription } from 'hooks/useSubscriptionFileReader';
import { Business } from 'types/business';
import { EDeliveryOption } from 'types';
import { EMAIL_REGEXP, MAX_LENGTH_EMAIL, MAX_LENGTH_NAME } from 'constants/general';
import { ESubscriptionBulkError, IBulkSubscriptionType } from 'types/subscription';

export const validateContactsEmail = (subscriptionArray: IBulkSubscriptionType[]): IBulkSubscriptionType[] => {
    const contactsEmail = new Set<string>();
    const repeatedAddresses = new Set<string>();

    subscriptionArray.forEach((subscription) => {
        const subscriptionEmailAddress = subscription.contact?.email;
        if (!subscriptionEmailAddress) return;

        const repeatedEmailAddress = contactsEmail.has(subscriptionEmailAddress);
        if (repeatedEmailAddress) repeatedAddresses.add(subscriptionEmailAddress);
        contactsEmail.add(subscriptionEmailAddress);
    });

    const validatedEmails = subscriptionArray.map((subscription) => {
        const subscriptionEmailAddress = subscription.contact?.email;
        if (!subscriptionEmailAddress) return subscription;

        if (
            subscription?.errorType?.includes(ESubscriptionBulkError.DUPLICATE_EMAIL) &&
            !repeatedAddresses.has(subscriptionEmailAddress)
        ) {
            const importError = subscription.errorType.filter(
                (item) => item !== ESubscriptionBulkError.DUPLICATE_EMAIL,
            );

            return {
                ...subscription,
                error: importError.length > 0,
                errorType: importError,
            };
        }

        if (!repeatedAddresses.has(subscriptionEmailAddress)) return subscription;

        return {
            ...subscription,
            error: true,
            errorType: subscription.errorType
                ? subscription.errorType?.concat(ESubscriptionBulkError.DUPLICATE_EMAIL)
                : [ESubscriptionBulkError.DUPLICATE_EMAIL],
        };
    });

    return validatedEmails;
};

type ValidateBulkSubscriptionProps = {
    subscriptionArray: BulkSubscription[];
    validateAddress: (organisationName: string, businesses?: Business[]) => boolean;
    activeBusiness?: Business;
    allowedBusinessesByFeature?: string[];
    businesses?: Business[];
    getBusinessByName: (organisationName: string) => Business | undefined;
    licencePlateRegExp: RegExp;
};

const validateBulkSubscription = ({
    subscriptionArray,
    validateAddress,
    activeBusiness,
    allowedBusinessesByFeature,
    businesses,
    getBusinessByName,
    licencePlateRegExp,
}: ValidateBulkSubscriptionProps): IBulkSubscriptionType[] => {
    const validateFormErrors = subscriptionArray.map((subscription) => {
        const userError = !subscription.email && !subscription.firstName && !subscription.lastName;

        const firstNameError = subscription.firstName.length > MAX_LENGTH_NAME;

        const lastNameError = subscription.lastName.length > MAX_LENGTH_NAME;

        const emailError = subscription.email.length > MAX_LENGTH_EMAIL;

        const unknownContactBool = userError && subscription.deliveryOption !== EDeliveryOption.HOME;

        const driverError = !unknownContactBool && (userError || !EMAIL_REGEXP.test(subscription.email));

        const typeError = !subscription.subscriptionType;

        const licencePlateError = !subscription.licencePlate || !licencePlateRegExp.test(subscription.licencePlate);

        const referenceError = !subscription.reference;

        const organisationError =
            !subscription.organisation ||
            (!activeBusiness?.subsidiaries?.some((sub) => {
                return sub.name === subscription.organisation;
            }) &&
                activeBusiness?.name !== subscription.organisation) ||
            !allowedBusinessesByFeature?.some((business) => business === subscription.organisation);

        const deliveryOptionError = !Object.values(EDeliveryOption).includes(
            subscription.deliveryOption as EDeliveryOption,
        );

        const addressError =
            validateAddress(subscription.organisation, businesses) &&
            subscription.deliveryOption === EDeliveryOption.MAIN;

        const organisation = getBusinessByName(subscription.organisation);

        const organisationObject = {
            id: organisation ? organisation.id : '',
            name: subscription.organisation,
        };

        const shipToPostalAddressId = organisation?.address?.id;

        const dataError =
            driverError ||
            firstNameError ||
            lastNameError ||
            emailError ||
            typeError ||
            licencePlateError ||
            referenceError ||
            organisationError ||
            deliveryOptionError ||
            addressError;

        return {
            unknownContact: unknownContactBool,
            type: subscription.subscriptionType,
            contact: {
                firstName: subscription.firstName,
                lastName: subscription.lastName,
                email: subscription.email,
            },
            licencePlate: subscription.licencePlate,
            reference: subscription.reference,
            organisation: organisationObject,
            shipToPostalAddressId,
            deliveryOption: subscription.deliveryOption as EDeliveryOption,
            errorType: dataError ? [ESubscriptionBulkError.IMPORT_ERROR] : [],
            error: dataError,
        };
    });

    const validatedData = validateContactsEmail(validateFormErrors);

    return validatedData;
};

export default validateBulkSubscription;
