import { headerHeight, maxWidthContent, screenWidthSmall, spaceL, spaceS } from 'styles/variables';
import Header from 'components/layout/Header';
import HeaderWithoutUserMenu from './HeaderWithoutUserMenu';
import styled from 'styled-components';

export const Container = styled.div`
    display: grid;
    grid-template-rows: ${headerHeight} 1fr;
    grid-template-columns: 1fr;
    grid-template-areas:
        'header'
        'main';
`;

export const ContentContainer = styled.main`
    grid-area: main;
    padding: ${spaceL} ${spaceS};
    overflow: hidden;
    justify-self: center;
    max-width: ${maxWidthContent};
    width: 100%;
    @media screen and (min-width: ${screenWidthSmall}) {
        padding: ${spaceL} 3.5rem;
    }
`;

type PageLayoutWithoutMenuProps = {
    children: React.ReactNode;
    headerWithUserMenu?: boolean;
};
function PageLayoutWithoutMenu({ children, headerWithUserMenu = true }: PageLayoutWithoutMenuProps): JSX.Element {
    return (
        <Container>
            {headerWithUserMenu ? <Header /> : <HeaderWithoutUserMenu />}
            <ContentContainer>{children}</ContentContainer>
        </Container>
    );
}

export default PageLayoutWithoutMenu;
