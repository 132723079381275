import { spaceM } from 'styles/variables';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import ActiveSubscriptionsKpi from './ActiveSubscriptionsKpi';
import Co2Kpi from './Co2Kpi';
import DynamicKPISection from './DynamicKPISection';
import TotalCostKpi from './TotalCostKpi';
import styled from 'styled-components';

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: ${spaceM};
    gap: ${spaceM};
`;

const KPIPageWrapper = styled.div`
    margin: ${spaceM} 0;
`;

export interface KPIPageProps {
    organisationsDropDown?: JSX.Element;
}

function KPIPage({ organisationsDropDown }: KPIPageProps): JSX.Element {
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.get('searchTerm')) {
            searchParams.delete('searchTerm');
            setSearchParams(searchParams);
        }
    }, [searchParams, setSearchParams]);

    return (
        <KPIPageWrapper>
            {organisationsDropDown}
            <Container>
                <TotalCostKpi />
                <ActiveSubscriptionsKpi />
                <Co2Kpi />
                <DynamicKPISection />
            </Container>
        </KPIPageWrapper>
    );
}

export default KPIPage;
