import { ECountry, ECurrency, ELocale } from 'types';

export type CountryContent = {
    licencePlateRegExp: RegExp;
    postalCodeRegExp: RegExp;
    dateFormatRegExp: RegExp;
    dateDelimiterRegExp: RegExp;
    locale: ELocale;
    currency: ECurrency;
    countryCode: ECountry;
    postalCodeLength: number;
    licencePlateMinLength: number;
    licencePlateMaxLength: number;
    dateFormat: string;
    datePlaceholder: string;
};

export const SE_CONTENT: CountryContent = {
    licencePlateRegExp: /^[a-zA-ZåäöÅÄÖ\d\s]{2,7}$/,
    postalCodeRegExp: /^\d{5}$/,
    dateFormatRegExp: /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12]\d|3[01])$/,
    dateDelimiterRegExp: /[^\d-]/g,
    dateFormat: 'y-MM-dd',
    datePlaceholder: 'YYYY-MM-DD',
    locale: ELocale.SV,
    currency: ECurrency.SEK,
    countryCode: ECountry.SE,
    postalCodeLength: 5,
    licencePlateMinLength: 2,
    licencePlateMaxLength: 7,
};

export const DE_CONTENT: CountryContent = {
    licencePlateRegExp: /^(?=.{1,10}$)[a-zA-Z]{1,3}-[a-zA-Z]{1,2} \d{1,4}$/,
    postalCodeRegExp: /^\d{5}$/,
    dateFormatRegExp: /(0[1-9]|1\d|2\d|3[01])\.(0[1-9]|1[012])\.(?:19|20)\d{2}/,
    dateDelimiterRegExp: /[^\d.]/g,
    dateFormat: 'dd.MM.yyyy',
    datePlaceholder: 'DD.MM.YYYY',
    locale: ELocale.DE,
    currency: ECurrency.EUR,
    countryCode: ECountry.DE,
    postalCodeLength: 5,
    licencePlateMinLength: 6,
    licencePlateMaxLength: 10,
};
