export const MAX_FILE_SIZE = 5; // in MB;
export const SPLIT_LINES = /\r\n|\n/;
export const DELIMITER_REGEX = /,|;|:|\/t|\|/g;
export const SPACES = /"+|\s+/g;
export const SPECIAL_CHARACTERS = /"+/g;

// Subscriptions
export const SUBSCRIPTION_FILE_COLUMNS = 8;

export const IMPORT_FILE_TEMPLATE = 'Subscription_order_template_';
