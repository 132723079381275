import { FieldError } from 'react-hook-form';
import { ForwardedRef, forwardRef } from 'react';
import {
    black,
    darkGrey,
    darkerGrey,
    font,
    lighterGrey,
    mediumGrey,
    primaryMerBlue,
    red,
    shadowBlueSharp,
    shadowRedSharp,
    spaceM,
    spaceS,
    spaceXl,
    spaceXs,
    spaceXxs,
    transitionDefault,
    white,
} from 'styles/variables';
import { useTranslation } from 'react-i18next';
import ArrowDownIcon from 'assets/icons/arrow_down.svg?react';
import FieldErrorMessage from 'components/forms/FieldError';
import LoadingSpinner from 'components/indicators/LoadingSpinner';
import styled from 'styled-components';
import uuid from 'utils/uuid';

const Container = styled.div<{
    width: string;
    $flexGrow: number;
    disabled: boolean;
    $error: boolean;
}>`
    width: ${(props) => props.width};
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: ${({ $flexGrow }) => $flexGrow};
    margin-bottom: ${spaceM};

    label {
        color: ${black};
        font-size: ${font.size.m};
        font-weight: ${font.weight.semiBold};
        line-height: ${font.lineHeight.s};
        margin-bottom: ${spaceXs};
        transition: color ${transitionDefault};
    }

    .wrapper {
        display: flex;
        flex-direction: row;
        position: relative;
        width: 100%;
        flex-grow: 1;

        .chevronIcon {
            color: ${({ disabled }) => (disabled ? mediumGrey : primaryMerBlue)};
            position: absolute;
            right: 0.75rem;
            top: 0.75rem;
            pointer-events: none;
        }

        select {
            flex-grow: 1;
            background-color: ${white};
            color: ${black};
            font-family: ${font.body};
            font-size: ${font.size.m};
            height: 3rem;
            padding: 0 ${spaceXl} 0 ${spaceS};
            border-width: 0.0625rem;
            border-style: solid;
            border-color: ${({ $error }) => ($error ? red : primaryMerBlue)};
            box-shadow: ${({ $error, disabled }) => ($error && !disabled ? shadowRedSharp : null)};
            appearance: none;
            border-radius: 0; //without border-radius: 0 the select will have curved borders in safari
            transition: all 0.3s ease-in-out;
            outline: none;
            width: 100%;

            &:focus {
                box-shadow: ${shadowBlueSharp};
            }

            &:disabled {
                color: ${darkerGrey};
                border-color: ${mediumGrey};
                background-color: ${lighterGrey};
            }
        }
    }

    .infoMessage {
        margin-top: ${spaceXxs};
    }
`;

const LoadingSpinnerContainer = styled.div`
    position: absolute;
    top: 45%;
    right: 0%;
    transform: translate(-50%, -50%);
    margin: auto;
`;

const LabelInfoWrapper = styled.div`
    display: flex;
    gap: ${spaceXs};
    align-items: center;
`;

const OptionalText = styled.span`
    margin-left: ${spaceXs};
    color: ${darkGrey};
`;

const InfoButtonWrapper = styled.div`
    margin-top: -0.6rem;
`;

export type DropDownInputOption = { value: string; text: string };

type Props = {
    id?: string;
    name: string;
    label?: string;
    options?: DropDownInputOption[];
    value?: string | number;
    disabled?: boolean;
    width?: string;
    flexGrow?: number;
    required?: boolean;
    fieldError?: FieldError | null;
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLSelectElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLSelectElement>) => void;
    className?: string;
    dataTestId?: string;
    defaultValue?: string;
    infoButton?: JSX.Element;
    infoMessage?: JSX.Element | null;
    isLoading?: boolean;
    hidePlaceholderOption?: boolean;
    customPlaceholderOption?: string;
    hideOptionalText?: boolean;
};

export default forwardRef(function DropdownInput(
    {
        id = uuid(),
        name,
        label,
        options,
        value,
        disabled = false,
        fieldError,
        width = '100%',
        flexGrow = 1,
        required = false,
        onChange,
        onFocus,
        onBlur,
        className,
        dataTestId,
        defaultValue,
        infoButton,
        infoMessage,
        isLoading = false,
        hidePlaceholderOption = false,
        customPlaceholderOption,
        hideOptionalText = false,
    }: Props,
    ref: ForwardedRef<never>,
): JSX.Element {
    const { t } = useTranslation();
    const showOptionalText = label && !required && !hideOptionalText;

    return (
        <Container
            width={width}
            $flexGrow={flexGrow}
            disabled={disabled || isLoading}
            $error={!!fieldError}
            className={`${isLoading ? 'loading' : ''} ${className}`}
            data-testid={dataTestId}
        >
            {label && (
                <LabelInfoWrapper>
                    <label htmlFor={id}>
                        {label}
                        {showOptionalText && <OptionalText>{`(${t('form.input.optionalFieldText')})`}</OptionalText>}
                    </label>
                    <InfoButtonWrapper>{infoButton}</InfoButtonWrapper>
                </LabelInfoWrapper>
            )}
            <span className="wrapper">
                <select
                    name={name}
                    id={id}
                    disabled={disabled || isLoading}
                    value={value}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    ref={ref}
                    required={required}
                    defaultValue={defaultValue}
                >
                    {!hidePlaceholderOption && (
                        <option value="" key="" data-testid="select">
                            {customPlaceholderOption ? t(customPlaceholderOption) : t('form.dropdown.select')}
                        </option>
                    )}
                    {options?.map((item) => (
                        <option key={`${item.value}_${item.text}`} value={item.value} data-testid={item.value}>
                            {item.text}
                        </option>
                    ))}
                </select>
                {isLoading ? (
                    <LoadingSpinnerContainer>
                        <LoadingSpinner />
                    </LoadingSpinnerContainer>
                ) : (
                    <ArrowDownIcon className="chevronIcon" />
                )}
            </span>
            {fieldError && !disabled ? (
                <FieldErrorMessage dataTestId={`errorMessage_${dataTestId}`} fieldError={fieldError} />
            ) : null}
            {infoMessage ? <span className="infoMessage">{infoMessage}</span> : null}
        </Container>
    );
});
