import { EPageErrorType, ESortingOrder } from 'types';
import { ESubscriptionSortingColumns } from 'types/subscription';
import { ETrackingOrigin } from 'types/tracking';
import { PAGE_SIZE } from 'constants/general';
import { PATH } from 'constants/paths';
import { darkGrey } from 'styles/variables';
import { formatDate } from 'utils/formatDate';
import { t } from 'i18next';
import { useCountrySpecificContent } from 'hooks/useCountrySpecificContent';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ErrorPage from 'pages/ErrorPage';
import GridList, { GridListHeader, GridListItem, Header } from 'components/lists/GridList';
import ListFooter from 'components/lists/ListFooter';
import SmallLinkButton from 'components/clickables/SmallLinkButton';
import SortableColumnHeader from 'components/lists/SortableColumnHeader';
import StatusPill from 'components/info/StatusPill';
import SubscriptionOrderStatusPopUp from './SubscriptionOrderStatusPopUp';
import TextEllipsisComponent, { TextEllipsis } from 'components/info/TextEllipsisComponent';
import styled from 'styled-components';
import useBusinessId from 'hooks/useBusinessId';
import useSubscriptionOrders from 'hooks/useSubscriptionOrders';

const gridColumn = '9.5rem minmax(3rem, 1fr) 7rem minmax(3rem, 1fr) 10.5rem minmax(3rem, 1fr) 4.5rem';

const StyledTextEllipsis = styled(TextEllipsis)`
    color: ${darkGrey};
`;

function SubscriptionOrderList(): JSX.Element {
    const { activeParentBusinessId } = useBusinessId();
    const [searchParams, setSearchParams] = useSearchParams();

    const searchTab = searchParams.get('tab');
    const querySearchParam = searchParams;

    if (searchTab !== 'order') {
        querySearchParam.set('tab', 'order');
        querySearchParam.delete('pageNumber');
        querySearchParam.delete('searchTerm');
    }

    const [sorting, setSorting] = useState({
        sortColumn: ESubscriptionSortingColumns.CREATION_TIME,
        sortDirection: ESortingOrder.DESC,
    });

    const searchPageNumber = searchParams.get('pageNumber');

    const [pageNumber, setPageNumber] = useState<number>(searchPageNumber ? Number(searchPageNumber) : 1);

    useEffect(() => {
        if (pageNumber === 1) {
            querySearchParam.set('pageNumber', '1');
        } else {
            querySearchParam.set('pageNumber', String(pageNumber));
        }

        setSearchParams(querySearchParam);
    }, [pageNumber, querySearchParam, setSearchParams]);

    const subscriptionsQueryData = {
        businessId: activeParentBusinessId,
        pageNumber: pageNumber - 1,
        pageSize: PAGE_SIZE,
        sortColumn: sorting.sortColumn,
        sortDirection: sorting.sortDirection,
    };

    const {
        subscriptionOrderList,
        error: errorSubscriptions,
        isLoading,
        mutateSubscriptionOrders,
    } = useSubscriptionOrders(subscriptionsQueryData);

    const haveSubscriptions = subscriptionOrderList && subscriptionOrderList.subscriptionOrders.length >= 1;

    const handlePagination = (page: number): void => {
        setPageNumber(page);
    };

    const getSortingDirection = (column: ESubscriptionSortingColumns): ESortingOrder => {
        const direction = sorting.sortColumn === column ? sorting.sortDirection : undefined;
        return direction as ESortingOrder;
    };

    const handleOnSortChange = (sortColumn: ESubscriptionSortingColumns, sortDirection: ESortingOrder): void => {
        setSorting({ sortColumn, sortDirection });
        mutateSubscriptionOrders();
    };

    const { locale } = useCountrySpecificContent();

    return (
        <>
            <GridList
                gridColumns={gridColumn}
                dataTestId="subscriptionsOrderList"
                showEmptyListMessage={subscriptionOrderList?.subscriptionOrders.length === 0 && !isLoading}
                isLoading={!haveSubscriptions && isLoading}
            >
                <GridListHeader>
                    <SortableColumnHeader
                        sortingDirection={getSortingDirection(ESubscriptionSortingColumns.STATUS)}
                        onSortChange={(direction) =>
                            handleOnSortChange(ESubscriptionSortingColumns.STATUS, direction as ESortingOrder)
                        }
                        infoButton={
                            <SubscriptionOrderStatusPopUp gaTrackingOrigin={ETrackingOrigin.SUBSCRIPTION_ORDER} />
                        }
                        text={t('general.status')}
                    />
                    <Header>{t('general.name')}</Header>
                    <Header>{t('general.licencePlate')}</Header>
                    <Header>{t('general.reference')}</Header>
                    <SortableColumnHeader
                        sortingDirection={getSortingDirection(ESubscriptionSortingColumns.CREATION_TIME)}
                        onSortChange={(direction) =>
                            handleOnSortChange(ESubscriptionSortingColumns.CREATION_TIME, direction as ESortingOrder)
                        }
                        text={t('general.creationDate')}
                    />
                    <Header>{t('general.subscription')}</Header>
                    <span />
                </GridListHeader>
                {haveSubscriptions && !errorSubscriptions && (
                    <>
                        {subscriptionOrderList.subscriptionOrders.map((order) => (
                            <GridListItem key={order.id}>
                                {order.status ? <StatusPill status={order.status} /> : <span />}
                                {order.unknownDriver ? (
                                    <StyledTextEllipsis data-testid="unpersonalisedOrder">
                                        {t('subscription.unpersonalised')}
                                    </StyledTextEllipsis>
                                ) : (
                                    <TextEllipsisComponent
                                        dataTestId="personalisedOrder"
                                        tooltipText={`${order.driver?.firstName} ${order.driver?.lastName}`}
                                    >{`${order.driver?.firstName} ${order.driver?.lastName}`}</TextEllipsisComponent>
                                )}
                                <TextEllipsis>{order.licencePlate}</TextEllipsis>
                                <TextEllipsisComponent tooltipText={order.reference}>
                                    {order.reference}
                                </TextEllipsisComponent>
                                <p>{formatDate(order.creationTime, locale)}</p>
                                <TextEllipsisComponent tooltipText={order.name}>{order.name}</TextEllipsisComponent>
                                <SmallLinkButton
                                    to={`${PATH.SUBSCRIPTION_OVERVIEW}/${PATH.SUBSCRIPTION_ORDER_DETAILS}?id=${order.id}&businessId=${order.businessId}`}
                                    text={t('general.button.view')}
                                />
                            </GridListItem>
                        ))}
                    </>
                )}
                {errorSubscriptions && <ErrorPage type={EPageErrorType.TEMPORARY} displayBorder={false} />}
            </GridList>
            {haveSubscriptions && !isLoading && (
                <ListFooter data={subscriptionOrderList} handlePagination={handlePagination} pageNumber={pageNumber} />
            )}
        </>
    );
}

export default SubscriptionOrderList;
