import { scrollToTop } from 'utils/scrollToTop';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop(): null {
    const { pathname } = useLocation();

    useEffect(() => {
        scrollToTop();
    }, [pathname]);

    return null;
}
