import { font, spaceXl, spaceXs } from 'styles/variables';
import styled from 'styled-components';

export const DetailsPageList = styled.dl`
    display: grid;
    grid-template-columns: auto 1fr;
    gap: ${spaceXs} ${spaceXl};
    line-height: ${font.lineHeight.l};
    dt {
        font-weight: ${font.weight.semiBold};
    }
    dd {
        word-break: break-word;
    }
`;
