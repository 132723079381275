import { ELocale, FileTypes } from 'types';
import { ETrackingAction, ETrackingEvent } from 'types/tracking';
import { IMPORT_FILE_TEMPLATE, MAX_FILE_SIZE } from 'constants/fileImport';
import { blue, font, primaryMerBlue, screenWidthMini, spaceL, spaceM, spaceS, spaceXs } from 'styles/variables';
import { useCallback } from 'react';
import { useCountrySpecificContent } from 'hooks/useCountrySpecificContent';
import { useTranslation } from 'react-i18next';
import BrowseFileButton from 'components/clickables/BrowseFileButton';
import DragDropFile from 'components/widgets/DragDropFile';
import FileUpload from 'assets/illustrations/file_upload.svg?react';
import ReactGA from 'react-ga4';
import styled from 'styled-components';

const FileInputInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 25rem;
    margin: 0 auto;

    @media screen and (max-width: ${screenWidthMini}) {
        max-width: unset;
    }

    h2 {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: ${spaceXs};
        margin: ${spaceM} 0 ${spaceS} 0;
        font-size: ${font.size.m};
        text-align: center;
        line-height: ${font.lineHeight.l};
    }

    input[type='file'] {
        display: none;
    }
`;

const SampleFile = styled.a`
    font-size: ${font.size.s};
    &:hover {
        background-color: transparent;
        font-weight: ${font.weight.semiBold};
    }
    &:focus {
        outline: none;
        font-weight: ${font.weight.semiBold};
    }
`;

const StyledBrowseFileButton = styled(BrowseFileButton)`
    height: ${spaceL};
    min-width: fit-content;
    &.btn--text {
        color: ${blue};
        display: inline-block;
        padding: 0;
        cursor: pointer;
        border: none;

        &:hover,
        &:focus {
            box-shadow: none;
            background-color: transparent;
            color: ${primaryMerBlue};
        }
    }
`;

type BrowseFileProps = {
    setError: (error: string | null) => void;
    setSelectedFile: (file: File) => void;
    clearFile?: boolean;
};

function BrowseFile({ setError, setSelectedFile, clearFile }: BrowseFileProps): JSX.Element {
    const { t, i18n } = useTranslation();

    const checkFileSize = useCallback(
        (file: File): string | null => {
            const fileSize = file.size / 1024 / 1024;
            if (fileSize > MAX_FILE_SIZE) {
                return t('widget.browseFile.fileSize');
            }
            return null;
        },
        [t],
    );

    const checkFileType = useCallback(
        (file: File, fileTypes: string[]): string | null => {
            const isFileType = fileTypes.includes(file.type);
            if (!isFileType) {
                return t('widget.browseFile.fileType');
            }
            return null;
        },
        [t],
    );

    const validateFiles = (file: File): string | null => {
        const error = checkFileType(file, [FileTypes.CSV, FileTypes.XLSX]);

        if (!error) {
            return checkFileSize(file);
        }

        return error;
    };

    const selectedFiles = (files: File[]): void => {
        if (files.length > 1) {
            setError(t('widget.browseFile.multiple'));
            return;
        }

        const [file] = files;
        const error = validateFiles(file);

        if (error) {
            setError(error);
        } else {
            setError(null);
        }

        setSelectedFile(file);
    };

    const { locale } = useCountrySpecificContent();
    const getUrlToDownloadFile = (): string => {
        const language = i18n.language === locale ? i18n.language : ELocale.EN;
        return `${import.meta.env.VITE_APP_MERHUB_STATIC}${IMPORT_FILE_TEMPLATE}${language}.xlsx`;
    };

    return (
        <DragDropFile selectedFiles={selectedFiles}>
            <FileInputInfo>
                <FileUpload height="32" />
                <h2>
                    {t('widget.browseFile.title')}
                    <StyledBrowseFileButton
                        clearFile={clearFile}
                        dataTestId="fileInput"
                        name="fileImport"
                        multiple={false}
                        accept=".csv, .xlsx"
                        setSelectedFile={selectedFiles}
                    >
                        {t('form.browseFileButton.label')}
                    </StyledBrowseFileButton>
                </h2>
                <SampleFile
                    data-testid="downloadSampleFile"
                    href={getUrlToDownloadFile()}
                    onClick={() =>
                        ReactGA.event(ETrackingEvent.IMPORT_SUBSCRIPTIONS, {
                            action: ETrackingAction.DOWNLOAD_FILE,
                        })
                    }
                >
                    {t('widget.importFileModal.sampleFile')}
                </SampleFile>
            </FileInputInfo>
        </DragDropFile>
    );
}

export default BrowseFile;
