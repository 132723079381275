import { ChangeEvent, ForwardedRef, forwardRef, memo } from 'react';
import { FieldError } from 'react-hook-form';
import { MAX_CHARACTER_LENGTH } from 'constants/general';
import {
    black,
    darkGrey,
    darkerGrey,
    font,
    lighterGrey,
    mediumGrey,
    primaryMerBlue,
    red,
    screenWidthMini,
    shadowBlueSharp,
    shadowRedSharp,
    spaceM,
    spaceS,
    spaceXl,
    spaceXs,
    white,
} from 'styles/variables';
import { useTranslation } from 'react-i18next';
import FieldErrorMessage from 'components/forms/FieldError';
import styled from 'styled-components';
import uuid from 'utils/uuid';

export interface Props {
    id?: string;
    name: string;
    minLength?: number;
    maxLength?: number;
    type?: string;
    required?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    autoComplete?: string;
    label?: string;
    width?: string;
    className?: string;
    flexGrow?: number;
    fieldError?: FieldError | null;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    mask?: (e: React.ChangeEvent<HTMLInputElement>) => string;
    adornment?: React.ReactNode;
    placeholder?: string;
    value?: string;
    StartIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    dataTestId?: string;
    infoMessage?: React.ReactNode;
    hideOptionalText?: boolean;
    displayErrorTextOnOneLine?: boolean;
}

export const Container = styled.div<{
    width: string;
    $flexGrow: number;
    disabled: boolean;
    $error: boolean;
    required: boolean;
    readOnly: boolean;
    margin?: string;
    $adornment?: boolean;
    $startIcon?: boolean;
}>`
    display: flex;
    flex-direction: column;
    flex-grow: ${({ $flexGrow }) => $flexGrow};
    position: relative;
    margin-bottom: ${({ margin }) => margin ?? spaceM};

    @media screen and (min-width: ${screenWidthMini}) {
        width: ${({ width }) => width};
    }

    label {
        color: ${black};
        font-size: ${font.size.m};
        font-weight: ${font.weight.semiBold};
        line-height: ${font.lineHeight.s};
        margin-bottom: ${spaceXs};
        transition: color 0.3s ease-in-out;
    }

    input {
        padding: ${({ $adornment, $startIcon }) =>
            `0 ${$adornment ? spaceXl : spaceS} 0 ${$startIcon ? spaceXl : spaceS}`};
        width: 100%;
        height: 3rem;
        color: ${black};
        background-color: ${white};
        border-width: 0.0625rem;
        border-style: solid;
        border-color: ${({ $error }) => ($error ? red : primaryMerBlue)};
        box-shadow: ${({ $error, disabled }) => ($error && !disabled ? shadowRedSharp : null)};
        font-weight: ${font.weight.regular};
        font-family: ${font.body};
        font-size: 1rem;
        transition: all 0.3s ease-in-out;
        outline: none;

        &:focus {
            box-shadow: ${shadowBlueSharp};
        }

        &:disabled {
            color: ${darkerGrey};
            border-color: ${mediumGrey};
            background-color: ${lighterGrey};
        }

        &:read-only {
            color: ${darkerGrey};
            border-color: ${mediumGrey};
            background-color: ${lighterGrey};
        }
    }

    &.paymentCard input {
        &:read-only {
            color: ${black};
        }
    }

    .adornment {
        position: absolute;
        right: 0;
        height: 3rem;
        text-align: right;
        padding: 0 ${spaceS};
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .startIcon {
        position: absolute;
        left: 0;
        height: 3rem;
        padding: 0 ${spaceS};
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const OptionalText = styled.span`
    margin-left: ${spaceXs};
    color: ${darkGrey};
`;

const TextInput: React.FC<Props> = forwardRef(
    (
        {
            id = uuid(),
            name,
            minLength,
            maxLength = MAX_CHARACTER_LENGTH,
            type = 'text',
            required = false,
            disabled = false,
            readOnly = false,
            autoComplete,
            label,
            width = '100%',
            fieldError,
            adornment,
            flexGrow = 1,
            className,
            onChange,
            onFocus,
            onBlur,
            onKeyDown,
            mask,
            placeholder,
            value,
            StartIcon,
            dataTestId,
            infoMessage,
            hideOptionalText = false,
            displayErrorTextOnOneLine = false,
        }: Props,
        ref: ForwardedRef<never>,
    ) => {
        const { t } = useTranslation();
        const showOptionalText = label && !required && !readOnly && !hideOptionalText;
        return (
            <Container
                width={width}
                $flexGrow={flexGrow}
                disabled={disabled}
                required={required}
                readOnly={readOnly}
                $error={!!fieldError}
                className={className}
                $adornment={!!adornment}
                $startIcon={!!StartIcon}
            >
                {label && (
                    <label htmlFor={id} data-testid={dataTestId ? `label_${dataTestId}` : ''}>
                        {label}
                        {showOptionalText && <OptionalText>{`(${t('form.input.optionalFieldText')})`}</OptionalText>}
                    </label>
                )}

                <input
                    id={id}
                    data-testid={dataTestId}
                    ref={ref}
                    minLength={minLength}
                    maxLength={maxLength}
                    name={name}
                    type={type}
                    readOnly={readOnly}
                    required={required}
                    disabled={disabled}
                    placeholder={placeholder}
                    onChange={
                        mask
                            ? (e: ChangeEvent<HTMLInputElement>) => {
                                  e.target.value = mask(e);
                                  onChange?.(e);
                              }
                            : onChange
                    }
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                    value={value}
                    autoComplete={autoComplete}
                    aria-required={required ? 'true' : 'false'}
                    aria-invalid={fieldError ? 'true' : 'false'}
                />
                {StartIcon ? (
                    <span className="startIcon">
                        <StartIcon />
                    </span>
                ) : null}
                {adornment ? <span className="adornment">{adornment}</span> : null}
                {fieldError ? (
                    <FieldErrorMessage
                        displayErrorTextOnOneLine={displayErrorTextOnOneLine}
                        dataTestId={dataTestId}
                        fieldError={fieldError}
                    />
                ) : null}
                {infoMessage}
            </Container>
        );
    },
);
export default memo(TextInput);
