import { CostItem, DynamicKpiData, EElectricCurrentType, EUsageType } from 'types/subscription';
import { ECurrency } from 'types';
import { font, spaceM, spaceS, spaceXxs } from 'styles/variables';
import { formatNumber } from 'utils/number';
import { getTotalKwh, getUsageByType } from 'utils/subscription/chart';
import { useCountrySpecificContent } from 'hooks/useCountrySpecificContent';
import { useCurrency } from 'hooks/useCurrency';
import { useTranslation } from 'react-i18next';
import Car from 'assets/icons/car.svg?react';
import Charger from 'assets/icons/charger_total_charging.svg?react';
import Coins from 'assets/icons/coins_large.svg?react';
import KpiContainer from 'components/kpi/KpiContainer';
import KpiContent from 'components/kpi/KpiContent';
import KpiListItem, { KpiList as KpiListContainer } from 'components/kpi/KpiListItem';
import Lightbulb from 'assets/illustrations/lightbulb.svg?react';
import PopUp from 'components/info/PopUp';
import SmallKpiContainer from 'components/kpi/KpiContainerSmall';
import TooltipContentComponent from 'components/info/TooltipContent';
import styled from 'styled-components';

const Container = styled.section`
    grid-row: span 6;
    > *:not(:last-child) {
        margin-bottom: ${spaceM};
    }
`;

const KpiListHeader = styled.h3`
    font-size: ${font.size.m};
    margin-bottom: ${spaceXxs};
`;

const StyledKpiListItem = styled(KpiListItem)<{ className: string }>`
    &.${({ className }) => className}:not(:has(~ .${({ className }) => className})):not(:last-child) {
        margin-bottom: ${spaceS};
    }
`;

type KpiListProps = {
    data?: DynamicKpiData;
};

type AdditionalCosts = {
    cost: CostItem;
    type: EUsageType;
};

type Cost = {
    amount: number;
    type: string;
};

type CostGroup = {
    currency: string;
    cost: Cost[];
};

function KpiList({ data }: KpiListProps): JSX.Element {
    const { t } = useTranslation();
    const { currency } = useCountrySpecificContent();
    const { toCurrency } = useCurrency();

    const getCostByTypeAndCurrency = (costItems: CostItem[]): CostItem => {
        return (
            costItems.find((cost) => cost.currency === currency) ?? {
                amount: 0,
                currency,
            }
        );
    };

    const totalCostForBUCurrency = getCostByTypeAndCurrency(data?.variableCost ?? []);
    const internalCostForBUCurrency = getCostByTypeAndCurrency(data?.internalLocationCost ?? []);
    const publicCostForBUCurrency = getCostByTypeAndCurrency(data?.publicLocationCost ?? []);

    const totalAdditionalInternalCosts = data?.internalLocationCost?.filter((cost) => cost.currency !== currency);
    const totalAdditionalPublicCosts = data?.publicLocationCost?.filter((cost) => cost.currency !== currency);

    const totalAdditionalCosts: AdditionalCosts[] = [];

    const addCostToTotalAdditionalCosts = (costs: CostItem[], type: EUsageType): void => {
        costs.forEach((cost) => {
            totalAdditionalCosts.push({
                cost,
                type,
            });
        });
    };

    if (totalAdditionalInternalCosts) {
        addCostToTotalAdditionalCosts(totalAdditionalInternalCosts, EUsageType.INTERNAL);
    }

    if (totalAdditionalPublicCosts) {
        addCostToTotalAdditionalCosts(totalAdditionalPublicCosts, EUsageType.PUBLIC);
    }

    const haveCostsInOtherCurrencies = totalAdditionalCosts && totalAdditionalCosts.length > 0;

    const totalAdditionalCostsArray: CostGroup[] = totalAdditionalCosts.reduce((acc: CostGroup[], current) => {
        const existingGroupIndex = acc.findIndex((group) => group.currency === current.cost.currency);
        if (existingGroupIndex !== -1) {
            acc[existingGroupIndex].cost.push({
                amount: current.cost.amount,
                type: current.type,
            });
        } else {
            acc.push({
                currency: current.cost.currency,
                cost: [
                    {
                        amount: current.cost.amount,
                        type: current.type,
                    },
                ],
            });
        }
        return acc;
    }, []);

    // Sort each cost group based on type
    const sortedAdditionalCostsArray = totalAdditionalCostsArray.map((costGroup) => {
        const sortedCosts = [...costGroup.cost].sort((a, b) => a.type.localeCompare(b.type));
        return {
            ...costGroup,
            cost: sortedCosts,
        };
    });

    const energyData = data?.consumedEnergyForCurrentType ?? [];
    return (
        <Container>
            <KpiContainer
                dataTestId="variableCostKpi"
                title={t('subscription.statistics.kpi.variableCost')}
                tooltip={
                    <PopUp
                        dataTestId="variableCostInfo"
                        content={
                            <TooltipContentComponent
                                headline={t('subscription.statistics.kpi.infoBoxVariableCost.headline')}
                                text={t('subscription.statistics.kpi.infoBoxVariableCost.text')}
                                icon={<Lightbulb />}
                            />
                        }
                    />
                }
                content={
                    <>
                        <KpiContent
                            icon={<Coins />}
                            text={toCurrency({ amount: totalCostForBUCurrency.amount, currency })}
                        />
                        <KpiListContainer>
                            <KpiListItem
                                title={t('usageType.INTERNAL')}
                                value={toCurrency({ amount: internalCostForBUCurrency.amount, currency })}
                            />
                            <KpiListItem
                                title={t('usageType.PUBLIC')}
                                value={toCurrency({ amount: publicCostForBUCurrency.amount, currency })}
                            />
                        </KpiListContainer>
                        {haveCostsInOtherCurrencies && (
                            <div>
                                <KpiListHeader>
                                    {t('subscription.statistics.kpi.totalCost.additionalCost')}
                                </KpiListHeader>
                                <KpiListContainer>
                                    {sortedAdditionalCostsArray.map((currencyCosts) =>
                                        currencyCosts.cost.map((cost) => (
                                            <StyledKpiListItem
                                                className={currencyCosts.currency}
                                                key={cost.amount}
                                                title={t(`usageType.${cost.type}`)}
                                                value={toCurrency({
                                                    amount: cost.amount,
                                                    currency: currencyCosts.currency as ECurrency,
                                                })}
                                            />
                                        )),
                                    )}
                                </KpiListContainer>
                            </div>
                        )}
                    </>
                }
            />
            <SmallKpiContainer
                imageContent={<Car />}
                title={t('subscription.statistics.kpi.totalDistance')}
                value={formatNumber(data?.drivenKilometers ?? 0)}
                unit="km"
                tooltip={
                    <PopUp
                        dataTestId="kilometerInfo"
                        content={
                            <TooltipContentComponent
                                headline={t('subscription.statistics.kpi.infoBoxKilometer.headline')}
                                text={t('subscription.statistics.kpi.infoBoxKilometer.text')}
                                icon={<Lightbulb />}
                            />
                        }
                    />
                }
                dataTestId="drivenKm"
            />

            <KpiContainer
                dataTestId="totalChargingKpi"
                title={t('subscription.statistics.kpi.totalCharging')}
                content={
                    <>
                        <KpiContent icon={<Charger />} text={`${formatNumber(getTotalKwh(energyData))} kWh`} />
                        <KpiListContainer>
                            <KpiListItem
                                title={t(`subscription.statistics.kpi.${EElectricCurrentType.AC}`)}
                                value={`${formatNumber(getUsageByType(EElectricCurrentType.AC, energyData))} kWh`}
                            />
                            <KpiListItem
                                title={t(`subscription.statistics.kpi.${EElectricCurrentType.DC}`)}
                                value={`${formatNumber(getUsageByType(EElectricCurrentType.DC, energyData))} kWh`}
                            />
                            <KpiListItem
                                title={t(`subscription.statistics.kpi.${EElectricCurrentType.UFC}`)}
                                value={`${formatNumber(getUsageByType(EElectricCurrentType.UFC, energyData))} kWh`}
                            />
                        </KpiListContainer>
                    </>
                }
            />
        </Container>
    );
}

export default KpiList;
