import { ButtonVariant } from 'types';
import { ETrackingEvent, ETrackingOrigin, ETrackingType } from 'types/tracking';
import { ModalTitle } from 'components/elements/ModalTitle';
import { spaceM, spaceS } from 'styles/variables';
import { useEffect, useState } from 'react';
import { useModal } from 'contexts/Modal';
import { useTranslation } from 'react-i18next';
import Button from 'components/clickables/Button';
import DateRange, { DateRangeFormat } from 'components/forms/DateRange';
import FilterSection from 'components/elements/FilterSection';
import ReactGA from 'react-ga4';
import styled from 'styled-components';
import useDateValidation, { useDateValidationReturnType } from 'hooks/useDateValidation';

const Container = styled.div`
    min-height: 25rem;
    display: flex;
    flex-direction: column;
`;

const ButtonContainer = styled.div`
    margin: ${spaceM};
    margin-top: auto;
    display: flex;

    gap: ${spaceS};
    button {
        flex: 1;
    }
`;

type ChargingHistoryFilter = {
    onFilter: (data: object) => void;
    filterData: { dateFrom: string; dateTo: string };
};

const validDates = (from: string, to: string, validators: useDateValidationReturnType): boolean => {
    if (
        validators.isValidDate(from) &&
        validators.isValidDate(to) &&
        !validators.isDateAfterToday(from) &&
        !validators.isDateAfterToday(to) &&
        !validators.isFromDateAfterToDate(from, to) &&
        validators.isValidDateRange(from, to)
    ) {
        return true;
    }
    return false;
};

function ChargingHistoryFilter({ onFilter, filterData }: ChargingHistoryFilter): JSX.Element {
    const { t } = useTranslation();
    const { close } = useModal();
    const dateValidators = useDateValidation();

    const [dateRange, setDateRange] = useState<DateRangeFormat>({
        from: filterData.dateFrom,
        to: filterData.dateTo,
    });
    const [dateRangeError, setDateRangeError] = useState(false);

    useEffect(() => {
        if (validDates(dateRange?.from, dateRange?.to, dateValidators) || (!dateRange?.from && !dateRange?.to)) {
            setDateRangeError(false);
        } else {
            setDateRangeError(true);
        }
    }, [dateRange, dateValidators]);

    const handleApplyFilter = (): void => {
        let dateFrom = '';
        let dateTo = '';

        if (dateRange?.from && dateRange?.to) {
            dateFrom = dateRange.from;
            dateTo = dateRange.to;
        }

        if (!dateRangeError) {
            onFilter({ dateFrom, dateTo });
            close();
        }

        ReactGA.event(ETrackingEvent.APPLY_FILTER, {
            origin: ETrackingOrigin.CHARGING_HISTORY,
            type: ETrackingType.DATEPICKER_FILTER,
        });
    };

    const clearFilter = (): void => {
        setDateRange({ from: '', to: '' });
    };

    return (
        <Container data-testid="chargingHistoryFilterModal">
            <ModalTitle>{t('filterModal.title')}</ModalTitle>
            <FilterSection
                title={t('filterModal.dateRange')}
                dataTestId="filterSectionDateRange"
                hasError={dateRangeError}
            >
                <DateRange range={dateRange} setRange={setDateRange} hideOptionalText />
            </FilterSection>
            <ButtonContainer>
                <Button variant={ButtonVariant.SECONDARY} onClick={clearFilter} dataTestId="clearFilterButton">
                    {t('filterModal.clear')}
                </Button>
                <Button dataTestId="applyFilterButton" onClick={handleApplyFilter}>
                    {t('filterModal.apply')}
                </Button>
            </ButtonContainer>
        </Container>
    );
}

export default ChargingHistoryFilter;
