import { EConnectorStatus } from 'types/facility';
import { ESubscriptionOrderStatus, ESubscriptionStatus } from 'types/subscription';
import { borderColorGrey, borderRadiusS, errorColor, midDarkGrey, orange, white } from 'styles/variables';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Text = styled.span`
    text-align: center;
    border-radius: ${borderRadiusS};
    padding: 0 0.75rem;
    border: 1px solid transparent;
    line-height: 1.5;

    &.default {
        border-color: ${borderColorGrey};
        background-color: ${white};
    }
    &.grey {
        border-color: ${midDarkGrey};
        background-color: ${midDarkGrey};
        color: ${white};
    }
    &.red {
        border-color: ${errorColor};
        background-color: ${errorColor};
        color: ${white};
    }
    &.orange {
        border-color: ${orange};
        background: ${orange};
        color: ${white};
    }
`;

type StatusPillProps = {
    status: ESubscriptionStatus | ESubscriptionOrderStatus | EConnectorStatus;
    className?: string;
    dataTestId?: string;
};

function StatusPill({ status, className, dataTestId }: StatusPillProps): JSX.Element {
    const { t } = useTranslation();
    let colorClass = '';
    switch (status) {
        case ESubscriptionStatus.INACTIVE:
            colorClass = 'grey';
            break;
        case EConnectorStatus.FAULTED:
        case ESubscriptionOrderStatus.FAILED:
            colorClass = 'red';
            break;
        case ESubscriptionOrderStatus.PENDING:
            colorClass = 'orange';
            break;
        default:
            colorClass = 'default';
            break;
    }

    return (
        <Text
            className={`${colorClass} ${className}`}
            data-testid={dataTestId ?? `statusPill_${status.toLocaleLowerCase()}`}
        >
            {t(`status.${[status.toLocaleLowerCase()]}`)}
        </Text>
    );
}

export default StatusPill;
