/* eslint-disable jsx-a11y/label-has-associated-control */
import { FieldError } from 'react-hook-form';
import {
    font,
    grey,
    primaryMerBlue,
    screenWidthMini,
    shadowBlueSharp,
    shadowCircular,
    spaceXs,
    white,
} from 'styles/variables';
import FieldErrorMessage from 'components/forms/FieldError';
import React, { ForwardedRef, memo } from 'react';
import styled from 'styled-components';

const ErrorContainer = styled.div`
    margin-left: 4rem;
`;

const Container = styled.div<{ $error: boolean; required?: boolean }>`
    position: relative;
    margin-bottom: 0.75rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @media screen and (max-width: ${screenWidthMini}) {
        padding-top: 0;
    }

    label {
        min-height: 2rem;
        position: relative;
        cursor: pointer;
        user-select: none;
        display: grid;
        grid-template-columns: 4rem 1fr;
        align-items: center;
        font-family: ${font.body}
        line-height: ${font.lineHeight.l};
        margin-bottom: ${(props) => (props.$error ? spaceXs : '0')};
    }

    .label-text {
        margin: 0;
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked ~ .checkmark {
            background-color: ${primaryMerBlue};
            &:after {
                left: calc(21px);
                border-color: ${primaryMerBlue};
                box-shadow: ${shadowCircular};
            }
        }

        &:focus ~ .checkmark {
            box-shadow: ${shadowBlueSharp};
        }
    }

    .checkmark {
        display: block;
        border-radius: 16px;
        width: 51px;
        height: 32px;
        background-color: ${grey};
        position: relative;
        transition: background-color, box-shadow 0.15s ease-in-out;
        &:after {
            cursor: pointer;
            border-radius: 50%;
            box-shadow: ${shadowCircular};
            content: '';
            width: 28px;
            height: 28px;
            background-color: ${white};
            position: absolute;
            top: 0;
            left: 2px;
            transition: border-color, left 0.15s ease-in-out;
            top: 50%;
            transform: translate(0, -50%);
        }
    }
`;

interface ToggleInputProps {
    name: string;
    id?: string;
    checked?: boolean;
    label: string | React.ReactNode;
    fieldError?: FieldError | null;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    required?: boolean;
    dataTestId?: string;
}

function ToggleInput(props: ToggleInputProps, ref: ForwardedRef<never>): JSX.Element {
    const {
        name,
        id,
        checked,
        label,
        fieldError,
        onChange,
        onFocus,
        onBlur,
        required,
        dataTestId = 'toggleButton',
    } = props;
    return (
        <Container $error={!!fieldError} required={required}>
            <label>
                <input
                    id={id}
                    data-testid={dataTestId}
                    name={name}
                    type="checkbox"
                    checked={checked}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    required={required}
                    ref={ref}
                    aria-required={required ? 'true' : 'false'}
                    aria-invalid={fieldError ? 'true' : 'false'}
                />
                <div className="checkmark" />
                <p className="label-text">{label}</p>
            </label>

            {fieldError ? (
                <ErrorContainer>
                    <FieldErrorMessage fieldError={fieldError} />
                </ErrorContainer>
            ) : null}
        </Container>
    );
}

export default memo(React.forwardRef(ToggleInput));
