import { Business, EBusinessFeature } from 'types/business';
import ApiError from 'classes/ApiError';
import useBusinessId from './useBusinessId';
import useBusinesses from './useBusinesses';

type useAuthorizationResult = {
    checkAuthorizationFeature: (feature: EBusinessFeature) => boolean;
    checkAuthorizationFeatureArray: (features: EBusinessFeature[]) => boolean;
    checkAuthorizationFeatureForBusiness: (features: EBusinessFeature, businessId: string) => boolean;
    isLoading: boolean;
    error?: ApiError;
};

const useAuthorization: () => useAuthorizationResult = () => {
    const { businesses, isLoading, error } = useBusinesses();
    const { activeParentBusinessId } = useBusinessId();

    const availableFeatures: EBusinessFeature[] = [];

    businesses.forEach((organisation: Business) => {
        if (organisation.id === activeParentBusinessId) {
            if (organisation.features) {
                availableFeatures.push(...organisation.features);
            }
            if (organisation.subsidiaries) {
                organisation.subsidiaries.forEach((sub) => {
                    if (sub.features) {
                        availableFeatures.push(...sub.features);
                    }
                });
            }
        }
    });

    const checkAuthorizationFeature = (feature: EBusinessFeature): boolean =>
        availableFeatures.some((item) => item === feature);

    // Check array of features
    const checkAuthorizationFeatureArray = (features: EBusinessFeature[]): boolean =>
        availableFeatures.some((feat) => features.includes(feat));

    const checkAuthorizationFeatureForBusiness = (feature: EBusinessFeature, businessId: string): boolean => {
        const availableFeaturesForBusiness: EBusinessFeature[] = [];

        businesses.forEach((organisation: Business) => {
            if (organisation.id === businessId && organisation.features) {
                availableFeaturesForBusiness.push(...organisation.features);
            } else if (organisation.subsidiaries) {
                organisation.subsidiaries.forEach((sub) => {
                    if (sub.id === businessId && sub.features) {
                        availableFeaturesForBusiness.push(...sub.features);
                    }
                });
            }
        });

        return availableFeaturesForBusiness.some((item) => item === feature);
    };

    return {
        isLoading,
        error,
        checkAuthorizationFeature,
        checkAuthorizationFeatureArray,
        checkAuthorizationFeatureForBusiness,
    };
};

export default useAuthorization;
