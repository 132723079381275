import { Address, EDeliveryOption } from 'types';
import { Business } from 'types/business';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

type ReturnAddressProp = {
    getAddress: (
        organisationName?: string,
        deliveryOption?: '' | EDeliveryOption,
        businesses?: Business[],
    ) => string[] | string | undefined;
    validateAddress: (organisationName: string, businesses?: Business[]) => boolean;
};

export const useAddress = (): ReturnAddressProp => {
    const { t } = useTranslation();

    const getAddress = useCallback(
        (
            organisationName?: string,
            deliveryOption?: '' | EDeliveryOption,
            businesses?: Business[],
        ): string[] | string | undefined => {
            let addressObject: Address | undefined;

            businesses?.forEach((business) => {
                if (business.name === organisationName) {
                    addressObject = business.address;
                }
                const subsidiary = business.subsidiaries?.find((sub) => sub.name === organisationName);

                if (subsidiary) {
                    addressObject = subsidiary.address;
                }
            });

            if (deliveryOption === EDeliveryOption.MAIN || deliveryOption === t('deliveryOption.COMPANY_ADDRESS')) {
                return [
                    `${addressObject?.addressLine1}, ${addressObject?.postalCode} ${addressObject?.city}, ${addressObject?.country}`,
                ];
            }

            if (deliveryOption === EDeliveryOption.HOME || deliveryOption === t('deliveryOption.HOME_ADDRESS')) {
                return '';
            }
            return '';
        },
        [t],
    );

    const validateAddress = (organisationName?: string, businesses?: Business[]): boolean => {
        let addressArray: (string | undefined)[] = [];

        businesses?.forEach((business) => {
            if (business.name === organisationName) {
                addressArray = [
                    business.address?.addressLine1,
                    business.address?.postalCode,
                    business.address?.city,
                    business.address?.country,
                ];
            }
            const subsidiary = business.subsidiaries?.find((sub) => sub.name === organisationName);

            if (subsidiary) {
                addressArray = [
                    subsidiary.address?.addressLine1,
                    subsidiary.address?.postalCode,
                    subsidiary.address?.city,
                    subsidiary.address?.country,
                ];
            }
        });

        return addressArray.includes(undefined) || addressArray.length === 0;
    };

    return { getAddress, validateAddress };
};
