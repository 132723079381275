import { AxiosResponse } from 'axios';
import { BlobFile, EExportFileType, ESortingOrder } from 'types';
import { ESubscriptionSortingColumns } from 'types/subscription';
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { getLanguageCode } from 'utils/getLanguageCode';
import useApi from 'hooks/useApi';

const getDownloadUrl = (params: GetSubscriptionsExportParams): string => {
    const { parentBusinessId, searchTerm, sortColumn, sortDirection, fileType } = params;
    const paramsArray: string[][] = [];
    const language = getLanguageCode();
    const selectedFileType = fileType ?? EExportFileType.EXCEL;

    paramsArray.push(['fileType', selectedFileType]);

    if (language) {
        paramsArray.push(['language', language]);
    }
    if (searchTerm) {
        paramsArray.push(['searchTerm', searchTerm]);
    }
    if (sortColumn) {
        paramsArray.push(['sortColumn', sortColumn]);
    }
    if (sortDirection) {
        paramsArray.push(['sortDirection', sortDirection]);
    }

    const urlParams = new URLSearchParams(paramsArray).toString();
    const url = `/v1/businesses/${parentBusinessId}/subscriptions/exports?${urlParams}`;
    return url;
};

type GetSubscriptionsExportParams = {
    parentBusinessId: string;
    searchTerm?: string;
    sortColumn?: ESubscriptionSortingColumns;
    sortDirection?: ESortingOrder;
    fileType?: EExportFileType;
};

const useSubscriptionsExport = (
    prop: GetSubscriptionsExportParams,
): UseMutationResult<BlobFile, unknown, void, unknown> => {
    const { get } = useApi<AxiosResponse>();

    const getExport = useMutation<BlobFile>({
        mutationFn: async () => {
            const response = await get(getDownloadUrl(prop), { download: true });
            const fileName = response.headers['content-disposition']?.replaceAll('attachment; filename=', '');
            return { file: response.data, fileName: fileName || 'export' };
        },
    });

    return getExport;
};

export default useSubscriptionsExport;
