import * as yup from 'yup';
import { ButtonType, ConsentFormType, DropDownType, EDeliveryOption, EToastType } from 'types';
import {
    CreateSubscriptionFormInputType,
    CreateSubscriptionOrderType,
    ESubscriptionFormType,
} from 'types/subscription';
import { EBusinessFeature, Organisation } from 'types/business';
import { EMAIL_REGEXP, MAX_LENGTH_EMAIL, MAX_LENGTH_NAME } from 'constants/general';
import { EMPTY_PERSONALIZED_SUBSCRIPTION, EMPTY_UNPERSONALIZED_SUBSCRIPTION } from 'constants/initialData';
import { ETrackingAction, ETrackingEvent, ETrackingOrigin, ETrackingType } from 'types/tracking';
import { Trans, useTranslation } from 'react-i18next';
import { licencePlateInputWidth, readingWidth, spaceL, spaceM, spaceS } from 'styles/variables';
import { scrollToTop } from 'utils/scrollToTop';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCountrySpecificContent } from 'hooks/useCountrySpecificContent';
import { useErrors } from 'contexts/ErrorContext';
import { useForm } from 'react-hook-form';
import { useModal } from 'contexts/Modal';
import { useSubscriptionPlans } from 'hooks/useSubscriptionPlans';
import { useToast } from 'contexts/Toast';
import { yupResolver } from '@hookform/resolvers/yup';
import ApiError from 'classes/ApiError';
import Button from 'components/clickables/Button';
import ConsentForm, { ConsentFormSchema } from 'components/consent/consentForm';
import DeliveryOptionsInfo from 'components/info/DeliveryOptionsInfo';
import DropdownInput from 'components/forms/DropdownInput';
import InfoIconButton from 'components/clickables/InfoIconButton';
import Lightbulb from 'assets/illustrations/lightbulb.svg?react';
import LinkText from 'components/clickables/LinkText';
import NotificationMessage, { EInfoType } from 'components/info/NotificationMessage';
import Page from 'components/layout/Page';
import PopUp from 'components/info/PopUp';
import ReactGA from 'react-ga4';
import SubscriptionTypesInfo from 'components/subscriptions/SubscriptionTypesInfo';
import Tabs, { Tab } from 'components/clickables/Tabs';
import TextInput from 'components/forms/TextInput';
import TooltipContent from 'components/info/TooltipContent';
import styled from 'styled-components';
import useBUContent from 'hooks/useBUContent';
import useBusinessId from 'hooks/useBusinessId';
import useBusinesses from 'hooks/useBusinesses';
import useContact from 'hooks/useContact';
import useGetBusiness from 'hooks/useGetBusiness';
import useSubscriptionOrder from 'hooks/useSubscriptionOrder';

const StyledSpan = styled.span`
    display: block;
    margin: ${spaceS} 0;
`;

const StyledTabs = styled(Tabs)`
    max-width: ${readingWidth};
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    margin: ${spaceL} 0;
    max-width: ${readingWidth};
`;

const FlexContainer = styled.div`
    display: flex;
    gap: ${spaceS};
`;

const LicencePlateInput = styled(TextInput)`
    max-width: ${licencePlateInputWidth};
`;

const StyledButton = styled(Button)`
    align-self: flex-start;
    margin-top: ${spaceM};
`;

const PopUpWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export type CombinedCreateSubscriptionType = ConsentFormType & CreateSubscriptionFormInputType;

const CreateSubscriptionSchema = (
    licencePlateRegExp: RegExp,
): yup.ObjectSchema<Omit<CreateSubscriptionFormInputType, 'organisation'>> =>
    yup.object().shape({
        id: yup.string(),
        type: yup.string().required('form.input.subscription.required'),
        unknownContact: yup.boolean().required(),
        contact: yup.object().when('unknownContact', {
            is: false,
            then: () =>
                yup.object().shape({
                    email: yup
                        .string()
                        .required('form.input.email.required')
                        .matches(EMAIL_REGEXP, { message: 'form.input.email.validation' }),
                    firstName: yup.string().required('form.input.firstName.required'),
                    lastName: yup.string().required('form.input.lastName.required'),
                }),
        }),
        licencePlate: yup
            .string()
            .required('form.input.licencePlate.required')
            .matches(licencePlateRegExp, { message: 'form.input.licencePlate.validation' }),
        reference: yup.string().required('form.input.reference.required'),
        deliveryOption: yup
            .mixed<EDeliveryOption>()
            .oneOf(Object.values(EDeliveryOption), 'form.input.deliveryOption.required')
            .required('form.input.deliveryOption.required'),
        organisationId: yup.string().required('form.input.organisation.required'),
    });

function CreateSubscriptionPage(): JSX.Element {
    const { t } = useTranslation();
    const { addToast } = useToast();
    const { errors: apiErrors, setErrors } = useErrors();
    const [selectedTab, setSelectedTab] = useState<Tab>({
        text: t(`subscriptionOverview.${ESubscriptionFormType.PERSONALISED}`),
        value: ESubscriptionFormType.PERSONALISED,
    });
    const [subscriptionOrder, setSubscriptionOrder] = useState<CreateSubscriptionFormInputType>(
        EMPTY_PERSONALIZED_SUBSCRIPTION,
    );
    const [selectedOrganisation, setSelectedOrganisation] = useState<Organisation | undefined>();
    const { error: errorBusinessId, isLoading: isLoadingBusinessId } = useBusinessId();
    const { businesses, error: errorBusinesses } = useBusinesses();
    const { getBusinessesListByFeature, getBusiness } = useGetBusiness(businesses);

    const organisationList = useMemo((): DropDownType[] | undefined => {
        return businesses ? getBusinessesListByFeature([EBusinessFeature.SUBSCRIPTION_MANAGEMENT_EDIT]) : undefined;
    }, [businesses, getBusinessesListByFeature]);

    const { buContent } = useBUContent();

    const { createSubscriptions, error: errorSubscription } = useSubscriptionOrder({
        businessId: selectedOrganisation?.id ?? '',
    });

    const { subscriptionPlans, isLoading: isLoadingSubscriptionPlans } = useSubscriptionPlans({
        businessId: selectedOrganisation?.id ?? '',
    });

    useEffect(() => {
        const errs: ApiError[] = [];
        if (errorBusinessId) {
            errs.push(errorBusinessId);
        }
        if (errorSubscription) {
            errs.push(errorSubscription);
        }
        if (errorBusinesses) {
            errs.push(errorBusinesses);
        }
        setErrors(errs);
    }, [errorBusinesses, errorBusinessId, errorSubscription, setErrors]);

    const onTabClick = useCallback((tab: Tab): void => {
        setSelectedTab(tab);
        if (tab.value === ESubscriptionFormType.PERSONALISED) {
            setSubscriptionOrder(EMPTY_PERSONALIZED_SUBSCRIPTION);
        } else {
            setSubscriptionOrder(EMPTY_UNPERSONALIZED_SUBSCRIPTION);
        }
    }, []);

    const tabsArray: Tab[] = Object.values(ESubscriptionFormType).map((subscriptionType) => ({
        text: t(`subscription.${subscriptionType}`),
        value: subscriptionType,
    }));

    const { licencePlateRegExp, licencePlateMinLength, licencePlateMaxLength, countryCode } =
        useCountrySpecificContent();

    const CombinedSchema = CreateSubscriptionSchema(licencePlateRegExp).concat(ConsentFormSchema);

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        reset,
        setValue,
        resetField,
    } = useForm<CombinedCreateSubscriptionType>({
        mode: 'onBlur',
        resolver: yupResolver(CombinedSchema),
        shouldUnregister: false,
        shouldFocusError: true,
        defaultValues: {
            ...EMPTY_UNPERSONALIZED_SUBSCRIPTION,
        },
    });

    const completeAddress =
        selectedOrganisation?.address?.addressLine1 &&
        selectedOrganisation?.address?.city &&
        selectedOrganisation?.address?.postalCode;
    const disabledDeliveryOption = selectedOrganisation && !completeAddress;

    const organisationId = watch('organisationId');
    const organisation = useMemo(
        () => (organisationId ? getBusiness(organisationId) : undefined),
        [getBusiness, organisationId],
    );

    useEffect(() => {
        resetField('type', { keepError: true });
        if (organisationId && businesses && organisation) {
            setSelectedOrganisation(organisation);
            if (selectedTab.value === ESubscriptionFormType.UNPERSONALISED) {
                if (!disabledDeliveryOption) {
                    setValue('deliveryOption', EDeliveryOption.MAIN, {
                        shouldValidate: true,
                    });
                } else {
                    setValue('deliveryOption', '', { shouldValidate: true });
                }
            }
        } else {
            setSelectedOrganisation(undefined);
            resetField('deliveryOption', { keepError: true });
        }
    }, [businesses, disabledDeliveryOption, organisation, organisationId, resetField, selectedTab.value, setValue]);

    useEffect(() => {
        if (subscriptionOrder) {
            reset(subscriptionOrder);
        }
    }, [reset, subscriptionOrder]);

    const { open } = useModal();
    const showSubscriptionPlansInfo = (): void => {
        if (subscriptionPlans) {
            ReactGA.event(ETrackingEvent.SUBSCRIPTION_PLANS_INFO, {
                action: ETrackingAction.OPEN_MODAL,
                origin: ETrackingOrigin.CREATE_SUBSCRIPTION,
            });
            open(<SubscriptionTypesInfo subscriptionPlans={subscriptionPlans} />);
        }
    };

    const onSubmit = async (data: CreateSubscriptionFormInputType): Promise<void> => {
        reset();
        scrollToTop();
        addToast({ message: t('subscription.create.subscriptionSent'), type: EToastType.INFO });

        try {
            const subscriptionPlan = subscriptionPlans?.find((plan) => plan.id === data.type);

            const order: CreateSubscriptionOrderType = {
                ...(!!data.contact && {
                    contact: { ...data.contact, countryCode },
                }),
                campaignId: subscriptionPlan?.campaignId ?? '',
                licencePlate: data.licencePlate,
                reference: data.reference,
                productId: data.type,
                deliveryOption: data.deliveryOption,
                countryCode,
                shipToPostalAddressId:
                    data.deliveryOption === EDeliveryOption.MAIN ? organisation?.address?.id : undefined,
            };

            ReactGA.event(ETrackingEvent.CREATE_SUBSCRIPTIONS, {
                type: order.contact ? ETrackingType.PERSONALISED : ETrackingType.UNPERSONALISED,
            });
            await createSubscriptions.mutateAsync(order);
            addToast({ message: t('subscription.create.successToast'), type: EToastType.SUCCESS });
        } catch (e) {
            if (e instanceof ApiError) {
                if (e.status === 409) {
                    addToast({ message: t('subscription.create.conflictError'), type: EToastType.ERROR });
                } else if (e.temporary) {
                    addToast({ message: t('general.errorToast'), type: EToastType.ERROR });
                } else {
                    addToast({
                        message: t('subscription.create.createSubscriptionError'),
                        type: EToastType.ERROR,
                    });
                }
            }
        }
    };

    const [isEmailFieldBlurred, setIsEmailFieldBlurred] = useState<boolean>(false);

    const isEmailCorrect = EMAIL_REGEXP.test(watch('contact.email') ?? '');
    const email = isEmailCorrect && isEmailFieldBlurred ? watch('contact.email') : '';

    const { contact, error: contactError } = useContact(organisationId || '', email);

    const onBlur = (e: { target: { value: string | undefined } }): void => {
        setValue('contact.email', e.target.value, { shouldValidate: true });
        setIsEmailFieldBlurred(true);
    };

    const firstNameValue = watch('contact.firstName');
    const lastNameValue = watch('contact.lastName');
    const nameMask = '*****';
    useEffect(() => {
        if (contact) {
            const firstName = contact.firstName ?? nameMask;
            const lastName = contact.lastName ?? nameMask;
            setValue('contact.firstName', firstName, { shouldValidate: true });
            setValue('contact.lastName', lastName, { shouldValidate: true });
        } else {
            resetField('contact.firstName', { keepError: false });
            resetField('contact.lastName', { keepError: false });
        }
    }, [contact, setValue, resetField]);

    const disabledSubscriptionPlan =
        !selectedOrganisation || isLoadingSubscriptionPlans || !subscriptionPlans || subscriptionPlans.length === 0;

    const showEmailErrorInfo = contactError && contactError.status !== 404;

    const deliveryOptions = [
        {
            text: t(`deliveryOption.${EDeliveryOption.HOME}`),
            value: EDeliveryOption.HOME,
        },
    ];

    if (!disabledDeliveryOption) {
        deliveryOptions.unshift({
            text: `${selectedOrganisation?.name} - ${selectedOrganisation?.address?.addressLine1}, ${selectedOrganisation?.address?.postalCode} ${selectedOrganisation?.address?.city}`,
            value: EDeliveryOption.MAIN,
        });
    }

    return (
        <Page
            title={t('subscription.create.title')}
            description={
                <Trans
                    i18nKey="subscription.create.description"
                    values={{
                        mailAddress: buContent.customerService.email,
                    }}
                    components={{
                        mailto: <LinkText to={`mailto:${buContent.customerService.email}`} />,
                        span: <StyledSpan />,
                    }}
                />
            }
            isLoading={isLoadingBusinessId}
            errors={apiErrors}
        >
            <StyledTabs tabs={tabsArray} activeTab={selectedTab} onTabClick={onTabClick} size="normal">
                <PopUpWrapper>
                    <PopUp
                        dataTestId="subscriptionTypeInfo"
                        content={
                            <TooltipContent
                                headline={t('subscription.create.subscriptionTypesInfo.title')}
                                text={t('subscription.create.subscriptionTypesInfo.description')}
                                icon={<Lightbulb />}
                            />
                        }
                    />
                </PopUpWrapper>
            </StyledTabs>
            <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                <DropdownInput
                    dataTestId="createSubscriptionFormOrganisation"
                    label={t('form.input.organisation.label')}
                    options={organisationList}
                    fieldError={errors.organisationId}
                    required
                    {...register('organisationId')}
                    customPlaceholderOption="form.input.organisation.placeHolder"
                />

                <DropdownInput
                    dataTestId="createSubscriptionFormPlan"
                    label={t('form.input.subscription.label')}
                    options={subscriptionPlans?.map((plan) => ({
                        value: plan.id,
                        text: plan.offer?.name || plan.name,
                    }))}
                    fieldError={errors.type}
                    required
                    infoButton={
                        <InfoIconButton
                            disabled={disabledSubscriptionPlan}
                            dataTestId="subscriptionPlanInfoButton"
                            onClick={showSubscriptionPlansInfo}
                        />
                    }
                    customPlaceholderOption="form.input.subscription.placeHolder"
                    infoMessage={
                        !!selectedOrganisation && !isLoadingSubscriptionPlans && disabledSubscriptionPlan ? (
                            <NotificationMessage
                                type={EInfoType.ERROR}
                                dataTestId="createSubscriptionFormPlanDisabled"
                                showTitle={false}
                                message={
                                    <Trans
                                        i18nKey="subscription.create.subscriptionPlanErrorMessage"
                                        values={{
                                            mailAddress: buContent.customerService.email,
                                            phoneNumber: buContent.customerService.phoneNumer,
                                        }}
                                        components={{
                                            mailto: <LinkText to={`mailto:${buContent.customerService.email}`} />,
                                        }}
                                    />
                                }
                            />
                        ) : null
                    }
                    {...register('type', { required: true })}
                    disabled={disabledSubscriptionPlan}
                    isLoading={isLoadingSubscriptionPlans}
                />
                {selectedTab.value === ESubscriptionFormType.PERSONALISED && (
                    <>
                        <TextInput
                            dataTestId="createSubscriptionFormEmail"
                            label={t('form.input.email.label')}
                            fieldError={errors.contact?.email}
                            maxLength={MAX_LENGTH_EMAIL}
                            required
                            infoMessage={
                                showEmailErrorInfo ? (
                                    <NotificationMessage
                                        type={EInfoType.ERROR}
                                        dataTestId="createSubscriptionFormEmailInfoMessage"
                                        showTitle={false}
                                        message={
                                            <Trans
                                                i18nKey="subscription.create.emailInfo"
                                                values={{
                                                    mailAddress: buContent.customerService.email,
                                                    phoneNumber: buContent.customerService.phoneNumer,
                                                }}
                                                components={{
                                                    mailto: (
                                                        <LinkText to={`mailto:${buContent.customerService.email}`} />
                                                    ),
                                                }}
                                            />
                                        }
                                    />
                                ) : null
                            }
                            {...register('contact.email', { required: true })}
                            onFocus={() => setIsEmailFieldBlurred(false)}
                            onBlur={onBlur}
                        />
                        <FlexContainer>
                            <TextInput
                                dataTestId="createSubscriptionFormFirstName"
                                label={t('form.input.firstName.label')}
                                maxLength={MAX_LENGTH_NAME}
                                required
                                fieldError={errors.contact?.firstName}
                                {...register('contact.firstName', { required: true })}
                                disabled={!!contact?.firstName || firstNameValue === nameMask}
                            />
                            <TextInput
                                dataTestId="createSubscriptionFormLastName"
                                label={t('form.input.lastName.label')}
                                maxLength={MAX_LENGTH_NAME}
                                required
                                fieldError={errors.contact?.lastName}
                                {...register('contact.lastName', { required: true })}
                                disabled={!!contact?.lastName || lastNameValue === nameMask}
                            />
                        </FlexContainer>
                    </>
                )}
                <LicencePlateInput
                    dataTestId="createSubscriptionFormLicencePlate"
                    displayErrorTextOnOneLine
                    maxLength={licencePlateMaxLength}
                    minLength={licencePlateMinLength}
                    label={t('form.input.licencePlate.label')}
                    required
                    fieldError={errors.licencePlate}
                    {...register('licencePlate', { required: true })}
                />
                <TextInput
                    dataTestId="createSubscriptionFormReference"
                    maxLength={25}
                    required
                    label={t('form.input.reference.label')}
                    fieldError={errors.reference}
                    {...register('reference', { required: true })}
                />
                <DropdownInput
                    dataTestId="createSubscriptionFormDeliveryOption"
                    label={t('form.input.deliveryOption.label')}
                    options={deliveryOptions}
                    fieldError={errors.deliveryOption}
                    required
                    infoButton={<DeliveryOptionsInfo />}
                    customPlaceholderOption="form.input.deliveryOption.placeHolder"
                    disabled={!organisationId || selectedTab.value === ESubscriptionFormType.UNPERSONALISED}
                    {...register('deliveryOption', { required: true })}
                    infoMessage={
                        disabledDeliveryOption ? (
                            <NotificationMessage
                                dataTestId="createSubscriptionFormDeliveryOptionInfo"
                                showTitle={false}
                                message={
                                    <Trans
                                        i18nKey="subscription.create.deliveryOptionInfo"
                                        values={{
                                            mailAddress: buContent.customerService.email,
                                            phoneNumber: buContent.customerService.phoneNumer,
                                        }}
                                        components={{
                                            mailto: <LinkText to={`mailto:${buContent.customerService.email}`} />,
                                        }}
                                    />
                                }
                            />
                        ) : null
                    }
                />
                <ConsentForm
                    termsAndConditionsRegister={register('termsAndConditionsConsent')}
                    termsAndConditionsError={errors.termsAndConditionsConsent}
                    hidePrivacyPolicy
                />
                <StyledButton dataTestId="createSubscriptionPageSend" type={ButtonType.SUBMIT}>
                    {t('form.button.sendOrder')}
                </StyledButton>
            </Form>
        </Page>
    );
}

export default CreateSubscriptionPage;
