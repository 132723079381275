import { borderRadiusS, font, lightRed, mediumGrey, red, spaceM, spaceS, spaceXs } from 'styles/variables';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from 'components/clickables/IconButton';
import MinusIcon from 'assets/icons/minus.svg?react';
import PlusIcon from 'assets/icons/plus.svg?react';
import styled from 'styled-components';

const FilterRow = styled.section<{ open: boolean }>`
    &:not(:last-of-type) {
        border-bottom: 1px solid ${mediumGrey};
    }
    &:last-of-type {
        border-bottom: ${({ open }) => (open ? '0' : `1px solid ${mediumGrey}`)};
    }
    padding-bottom: ${({ open }) => (open ? `${spaceS}` : '0')};
    margin: 0 ${spaceM};
    .title {
        display: flex;
        align-items: center;
        padding: ${spaceS} 0;
        gap: ${spaceXs};
    }
`;

const SectionTitle = styled.h2`
    font-size: ${font.size.m};
    font-weight: ${font.weight.regular};
`;
const ErrorText = styled.div`
    background: ${lightRed};
    color: ${red};
    padding: 0 ${spaceXs};
    border-radius: ${borderRadiusS};
    font-size: ${font.size.xs};
    height: 2rem;
    display: flex;
    place-items: center;
`;

const StyledIconButton = styled(IconButton)`
    margin-left: auto;
`;

type FilterSectionProps = {
    title: string;
    children: React.ReactNode | React.ReactNode[];
    expanded?: boolean;
    dataTestId?: string;
    hasError?: boolean;
};

function FilterSection({
    title,
    children,
    expanded = true,
    dataTestId,
    hasError = false,
}: FilterSectionProps): JSX.Element {
    const { t } = useTranslation();
    const [open, setOpen] = useState(expanded);

    return (
        <FilterRow open={open} data-testid={dataTestId}>
            <div className="title">
                <SectionTitle>{title} </SectionTitle>
                {hasError && !open ? <ErrorText>{t('filterModal.invalidSection')}</ErrorText> : null}
                <StyledIconButton
                    onClick={() => setOpen((prevStat) => !prevStat)}
                    type="button"
                    aria-label={open ? t('general.collapse') : t('general.expand')}
                >
                    {open ? <MinusIcon /> : <PlusIcon />}
                </StyledIconButton>
            </div>
            {open && children}
        </FilterRow>
    );
}

export default FilterSection;
