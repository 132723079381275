import { ESortingOrder } from 'types';
import { ESubscriptionSortingColumns, SubscriptionsResult } from 'types/subscription';
import { PAGE_SIZE } from 'constants/general';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import ApiError from 'classes/ApiError';
import useApi from 'hooks/useApi';

export type useSubscriptionsResponse = {
    subscriptionList?: SubscriptionsResult;
    isLoading: boolean;
    error?: ApiError;
    mutateSubscriptions: () => void;
};

type GetSubscriptionsParams = {
    parentBusinessId: string;
    pageNumber?: number;
    pageSize?: number;
    searchTerm?: string;
    sortColumn?: ESubscriptionSortingColumns;
    sortDirection?: ESortingOrder;
};

const useSubscriptions = (prop: GetSubscriptionsParams): useSubscriptionsResponse => {
    const { parentBusinessId, pageNumber, pageSize = PAGE_SIZE, searchTerm, sortColumn, sortDirection } = prop;

    const paramsArray: string[][] = [];
    if (searchTerm !== undefined && searchTerm !== null && searchTerm.length > 0) {
        paramsArray.push(['searchTerm', searchTerm]);
    }
    if (pageSize !== undefined && pageSize !== null) {
        paramsArray.push(['pageSize', pageSize.toString()]);
    }
    if (pageNumber !== undefined && pageNumber !== null) {
        paramsArray.push(['pageNumber', pageNumber.toString()]);
    }
    if (sortColumn && sortDirection) {
        paramsArray.push(['sortColumn', sortColumn.toString()], ['sortDirection', sortDirection.toString()]);
    }

    const { get } = useApi<SubscriptionsResult>();
    const urlParams = new URLSearchParams(paramsArray).toString();
    const url = `/v1/businesses/${parentBusinessId}/subscriptions?${urlParams}`;
    const fetcher: () => Promise<SubscriptionsResult> = () => get(url);
    const {
        data: subscriptionList,
        isFetching: isLoading,
        error,
    } = useQuery<SubscriptionsResult, ApiError>({ queryKey: [url], queryFn: fetcher });

    const queryClient = useQueryClient();
    const mutateSubscriptions = (): void => {
        queryClient.invalidateQueries({ queryKey: [url] });
    };

    return {
        subscriptionList,
        mutateSubscriptions,
        isLoading,
        error: error ?? undefined,
    };
};

export default useSubscriptions;
