import { Price } from 'types';

interface IBaseProduct {
    id: string;
    name: string;
    image: Image;
    price: Price;
    description: string;
    type?: EProductType;
}

interface IOffer {
    id: string;
    name: string;
}

export interface IProduct extends IBaseProduct {
    code: string;
    campaignId: string;
    businessId: string;
    subProducts: IBaseProduct[];
    optionalProducts: IBaseProduct[];
    status?: EProductStatus;
}

export interface ISubscriptionPlan extends IProduct {
    offer: IOffer;
    subProducts: SubscriptionSubProduct[];
}

export interface SubscriptionSubProduct extends IBaseProduct {
    offer: IOffer;
    code: string;
}

export type Image = {
    url: string;
};

export enum EProductStatus {
    ACTIVE = 'ACTIVE',
}

export enum EProductType {
    ACCESSORY = 'ACCESSORY',
    SUBSCRIPTION = 'SUBSCRIPTION',
}
