import { EBusinessFeature } from 'types/business';
import { EPageErrorType } from 'types';
import { FEATURE_LIST, showFeature } from 'utils/featureFlag';
import { PATH } from 'constants/paths';
import { Route, Routes } from 'react-router-dom';
import AuthRoute from 'components/AuthRoute';
import CreateSubscriptionPage from 'pages/subscription/CreateSubscriptionPage';
import ErrorPage from 'pages/ErrorPage';
import FacilityManagementPage from 'pages/facility/FacilityManagementPage';
import FacilityStatisticsPage from 'pages/facility/FacilityStatisticsPage';
import MyAccountPage from 'pages/MyAccountPage';
import PageLayout from 'components/layout/PageLayout';
import PageLayoutWithoutMenu from 'components/layout/PageLayoutWithoutMenu';
import RouteWrapper from 'components/wrappers/RouteWrapper';
import SignUpPage from 'pages/SignUpPage';
import Startpage from 'pages/Startpage';
import SubscriptionDetailsPage from 'pages/subscription/SubscriptionDetailsPage';
import SubscriptionImportPage from 'pages/subscription/SubscriptionImportPage';
import SubscriptionOrderDetailsPage from 'pages/subscription/SubscriptionOrderDetailsPage';
import SubscriptionOverviewPage from 'pages/subscription/SubscriptionOverviewPage';
import SubscriptionStatisticsPage from 'pages/subscription/SubscriptionStatisticsPage';

function AppRoutes(): JSX.Element {
    return (
        <Routes>
            <Route path={PATH.SIGNUP} element={<SignUpPage />} />
            <Route element={<RouteWrapper />}>
                <Route
                    path={PATH.STARTPAGE}
                    element={
                        <PageLayout>
                            <Startpage />
                        </PageLayout>
                    }
                />
                <Route
                    path={PATH.SUBSCRIPTION_CREATE}
                    element={
                        <PageLayout>
                            <AuthRoute feature={[EBusinessFeature.SUBSCRIPTION_MANAGEMENT_EDIT]}>
                                <CreateSubscriptionPage />
                            </AuthRoute>
                        </PageLayout>
                    }
                />
                <Route
                    path={PATH.SUBSCRIPTION_IMPORT}
                    element={
                        <PageLayout>
                            <AuthRoute feature={[EBusinessFeature.SUBSCRIPTION_MANAGEMENT_EDIT]}>
                                <SubscriptionImportPage />
                            </AuthRoute>
                        </PageLayout>
                    }
                />
                <Route
                    path={PATH.SUBSCRIPTION_STATISTICS}
                    element={
                        <PageLayout>
                            <AuthRoute
                                feature={[
                                    EBusinessFeature.SUBSCRIPTION_MANAGEMENT_EDIT,
                                    EBusinessFeature.SUBSCRIPTION_MANAGEMENT_VIEW,
                                ]}
                            >
                                <SubscriptionStatisticsPage />
                            </AuthRoute>
                        </PageLayout>
                    }
                />

                <Route path={PATH.SUBSCRIPTION_OVERVIEW}>
                    <Route
                        index
                        element={
                            <PageLayout>
                                <AuthRoute
                                    feature={[
                                        EBusinessFeature.SUBSCRIPTION_MANAGEMENT_EDIT,
                                        EBusinessFeature.SUBSCRIPTION_MANAGEMENT_VIEW,
                                    ]}
                                >
                                    <SubscriptionOverviewPage />
                                </AuthRoute>
                            </PageLayout>
                        }
                    />
                    <Route
                        path={PATH.SUBSCRIPTION_DETAILS}
                        element={
                            <PageLayout>
                                <AuthRoute
                                    feature={[
                                        EBusinessFeature.SUBSCRIPTION_MANAGEMENT_EDIT,
                                        EBusinessFeature.SUBSCRIPTION_MANAGEMENT_VIEW,
                                    ]}
                                >
                                    <SubscriptionDetailsPage />
                                </AuthRoute>
                            </PageLayout>
                        }
                    />
                    <Route
                        path={PATH.SUBSCRIPTION_ORDER_DETAILS}
                        element={
                            <PageLayout>
                                <AuthRoute
                                    feature={[
                                        EBusinessFeature.SUBSCRIPTION_MANAGEMENT_EDIT,
                                        EBusinessFeature.SUBSCRIPTION_MANAGEMENT_VIEW,
                                    ]}
                                >
                                    <SubscriptionOrderDetailsPage />
                                </AuthRoute>
                            </PageLayout>
                        }
                    />
                </Route>

                <Route
                    path={PATH.MY_ACCOUNT}
                    element={
                        <PageLayout>
                            <MyAccountPage />
                        </PageLayout>
                    }
                />
                <Route
                    path={PATH.WILDCARD}
                    element={
                        <PageLayout>
                            <ErrorPage type={EPageErrorType.BROKEN_URL} />
                        </PageLayout>
                    }
                />

                <Route
                    path={PATH.ERROR}
                    element={
                        <PageLayout>
                            <ErrorPage type={EPageErrorType.TEMPORARY} />
                        </PageLayout>
                    }
                />
                <Route
                    path={PATH.CRITICAL_ERROR_NO_MENU}
                    element={
                        <PageLayoutWithoutMenu>
                            <ErrorPage type={EPageErrorType.CRITICAL_ERROR_NO_MENU} />
                        </PageLayoutWithoutMenu>
                    }
                />
                <Route
                    path={PATH.TEMPORARY_ERROR_NO_MENU}
                    element={
                        <PageLayoutWithoutMenu>
                            <ErrorPage type={EPageErrorType.TEMPORARY_ERROR_NO_MENU} home refresh />
                        </PageLayoutWithoutMenu>
                    }
                />
                <Route
                    path={PATH.NO_PERMISSION}
                    element={
                        <PageLayout>
                            <ErrorPage type={EPageErrorType.NO_PERMISSION} />
                        </PageLayout>
                    }
                />
                <Route
                    path={PATH.FACILITY_STATISTICS}
                    element={
                        <PageLayout>
                            <AuthRoute
                                feature={[
                                    EBusinessFeature.FACILITY_MANAGEMENT_EDIT,
                                    EBusinessFeature.FACILITY_MANAGEMENT_VIEW,
                                ]}
                            >
                                <FacilityStatisticsPage />
                            </AuthRoute>
                        </PageLayout>
                    }
                />
                {showFeature(FEATURE_LIST.FACILITY_MANAGEMENT) && (
                    <Route
                        path={PATH.FACILITY_MANAGEMENT}
                        element={
                            <PageLayout>
                                <AuthRoute
                                    feature={[
                                        EBusinessFeature.FACILITY_MANAGEMENT_EDIT,
                                        EBusinessFeature.FACILITY_MANAGEMENT_VIEW,
                                    ]}
                                >
                                    <FacilityManagementPage />
                                </AuthRoute>
                            </PageLayout>
                        }
                    />
                )}
            </Route>
        </Routes>
    );
}

export default AppRoutes;
