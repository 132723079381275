/* eslint-disable @typescript-eslint/no-unused-vars */
import { parse } from 'tinyduration';
import { t } from 'i18next';

export const formatISODuration = (duration: string): string => {
    if (duration) {
        try {
            const obj = parse(duration);
            const time = [];
            if (obj.days && obj.days > 0) {
                const daysInHours = obj.days * 24;
                const totalHours = daysInHours + (obj?.hours ?? 0);
                time.push(`${totalHours}${t('time.shortHour')}`);
            } else if (obj.hours !== undefined) {
                time.push(`${obj.hours}${t('time.shortHour')}`);
            }
            if (obj.minutes !== undefined) {
                time.push(`${obj.minutes}${t('time.shortMinute')}`);
            }
            if (obj.seconds !== undefined) {
                time.push(`${obj.seconds}${t('time.shortSecond')}`);
            }

            return time.join(' ');
        } catch (e) {
            return '';
        }
    }

    return '';
};
