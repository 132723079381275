import { generateActiveSubscriptions, getActiveSubscriptionsTotalNumber } from 'utils/subscription/chart';
import { useContext, useEffect } from 'react';
import { useErrors } from 'contexts/ErrorContext';
import { useTranslation } from 'react-i18next';
import KpiContainer from 'components/kpi/KpiContainer';
import KpiContent from 'components/kpi/KpiContent';
import KpiListItem, { KpiList } from 'components/kpi/KpiListItem';
import SmallThunder from 'assets/icons/small_thunder.svg?react';
import SubscriptionStatisticsContext from 'contexts/SubscriptionStatisticsContext';
import useActiveSubscriptions from 'hooks/useActiveSubscriptions';
import useBusinessId from 'hooks/useBusinessId';

function ActiveSubscriptionsKpi(): JSX.Element {
    const { t } = useTranslation();
    const { activeParentBusinessId } = useBusinessId();

    const organisationIds = useContext(SubscriptionStatisticsContext);

    const {
        activeSubscriptions = [],
        error: errorActiveSubscriptions,
        isLoading: isLoadingActiveSubscriptions,
    } = useActiveSubscriptions({
        parentBusinessId: activeParentBusinessId,
        businessIds: organisationIds.length > 1 ? [] : organisationIds,
    });
    const { setError } = useErrors();

    useEffect(() => {
        if (setError && !isLoadingActiveSubscriptions && errorActiveSubscriptions) {
            setError(errorActiveSubscriptions);
        }
    }, [setError, errorActiveSubscriptions, isLoadingActiveSubscriptions]);

    const chartData = generateActiveSubscriptions(activeSubscriptions);
    const total = getActiveSubscriptionsTotalNumber(activeSubscriptions);

    return (
        <KpiContainer
            dataTestId="activeSubscriptionsKpi"
            title={t('subscription.statistics.kpi.activeSubscriptions.title')}
            content={
                <>
                    <KpiContent icon={<SmallThunder />} text={total.toString()} />
                    <KpiList>
                        {chartData.map((subscription, idx) => {
                            return (
                                <KpiListItem
                                    key={subscription.name}
                                    dataTestId={`subscription_${idx}`}
                                    title={subscription.name}
                                    value={subscription.value}
                                />
                            );
                        })}
                    </KpiList>
                </>
            }
        />
    );
}

export default ActiveSubscriptionsKpi;
