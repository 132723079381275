import { PATH } from 'constants/paths';
import { maxWidthContent, spaceM } from 'styles/variables';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from 'components/clickables/Button';
import Page from 'components/layout/Page';
import SubscriptionOrderDetailsPanel from 'components/subscriptions/subscriptionDetails/SubscriptionOrderDetailsPanel';
import styled from 'styled-components';
import useSubscriptionOrder from 'hooks/useSubscriptionOrder';

const Container = styled.section`
    display: grid;
    grid-template-columns: minmax(22rem, calc(${maxWidthContent} / 2));
    gap: ${spaceM};
`;

const StyledButton = styled(Button)`
    justify-self: flex-start;
`;

function SubscriptionOrderDetailsPage(): JSX.Element {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const orderId = searchParams.get('id') ?? undefined;
    const businessId = searchParams.get('businessId') ?? undefined;

    const handleNavigate: () => void = () => navigate(-1);

    useEffect(() => {
        if (!orderId) {
            navigate(PATH.ERROR);
        }
    }, [orderId, navigate]);

    const {
        subscriptionOrder,
        isLoading: isLoadingSubscription,
        patchSubscriptionOrder,
        error: errorSubscription,
    } = useSubscriptionOrder({
        businessId,
        orderId,
    });

    const isLoading = isLoadingSubscription;

    const apiErrors = [];
    if (!isLoadingSubscription && errorSubscription) {
        apiErrors.push(errorSubscription);
    }

    return (
        <Page title={t('subscription.details.orderTitle')} isLoading={isLoading} errors={apiErrors}>
            <Container>
                {subscriptionOrder && (
                    <SubscriptionOrderDetailsPanel
                        patchSubscription={patchSubscriptionOrder}
                        subscriptionOrder={subscriptionOrder}
                    />
                )}
                <StyledButton dataTestId="backButton" onClick={handleNavigate}>
                    {t('general.navigateBack')}
                </StyledButton>
            </Container>
        </Page>
    );
}

export default SubscriptionOrderDetailsPage;
