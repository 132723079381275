import { ESortingOrder } from 'types';
import { black, font, spaceXxs } from 'styles/variables';
import ArrowDown from 'assets/icons/sorting_arrow_down.svg?react';
import ArrowUp from 'assets/icons/sorting_arrow_up.svg?react';
import styled from 'styled-components';

const TextWithSorting = styled.button`
    display: flex;
    gap: ${spaceXxs};
    align-items: center;
    background: transparent;
    border: none;
    cursor: pointer;

    font-family: ${font.body};
    font-weight: ${font.weight.bold};
    font-size: ${font.size.m};
    color: ${font.color.default};
    svg {
        color: ${black};
    }
    &:hover {
        background: none;
    }

    &:active {
        background: none;
    }
`;

const Container = styled.div`
    display: flex;
`;

type SortableColumnHeaderProps = {
    sortingDirection?: ESortingOrder;
    onSortChange: (v: string) => void;
    text: string;
    infoButton?: React.ReactNode;
};

function SortableColumnHeader({
    sortingDirection,
    onSortChange,
    text,
    infoButton,
}: SortableColumnHeaderProps): JSX.Element {
    return (
        <Container>
            <TextWithSorting
                type="button"
                onClick={() =>
                    onSortChange(sortingDirection === ESortingOrder.ASC ? ESortingOrder.DESC : ESortingOrder.ASC)
                }
            >
                {text}
                {sortingDirection === ESortingOrder.ASC ? <ArrowUp /> : <ArrowDown />}
            </TextWithSorting>
            {infoButton}
        </Container>
    );
}

export default SortableColumnHeader;
