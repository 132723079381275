type LinkTextProps = {
    to: string;
    title?: string;
    children?: React.ReactNode;
    className?: string;
};

function LinkText({ to, title = '', children, className = '' }: LinkTextProps): JSX.Element {
    return (
        <a href={to} target="_blank" title={title} rel="noreferrer" className={className}>
            {children}
        </a>
    );
}

export default LinkText;
