export enum PATH {
    STARTPAGE = '/',
    SIGNUP = '/signup',
    MY_ACCOUNT = '/myAccount',

    SUBSCRIPTION_CREATE = '/createSubscription',
    SUBSCRIPTION_OVERVIEW = '/subscriptions',
    SUBSCRIPTION_DETAILS = 'subscriptionDetails',
    SUBSCRIPTION_ORDER_DETAILS = 'orderDetail',
    SUBSCRIPTION_STATISTICS = '/subscriptionStatistics',
    SUBSCRIPTION_IMPORT = '/import/subscriptions',

    ERROR = '/error',
    TEMPORARY_ERROR_NO_MENU = '/temporaryError',
    CRITICAL_ERROR_NO_MENU = '/criticalError',
    NO_PERMISSION = '/accessDenied',

    WILDCARD = '/*',

    FACILITY_MANAGEMENT = '/facilityManagement',
    FACILITY_STATISTICS = '/facilityStatistics',
}
