import { ETrackingOrigin } from 'types/tracking';
import { useTranslation } from 'react-i18next';
import StatusInfoPopup from 'components/info/StatusInfoPopup';

type SubscriptionOrderStatusPopUpProps = {
    gaTrackingOrigin: ETrackingOrigin;
};

function SubscriptionOrderStatusPopUp({ gaTrackingOrigin }: SubscriptionOrderStatusPopUpProps): JSX.Element {
    const { t } = useTranslation();

    const statusList = [
        {
            title: t('status.pending'),
            text: t('subscription.statuses.pending'),
        },
        {
            title: t('status.completed'),
            text: t('subscription.statuses.completed'),
        },
        {
            title: t('status.failed'),
            text: t('subscription.statuses.failed'),
        },
    ];

    return <StatusInfoPopup statusList={statusList} gaTrackingOrigin={gaTrackingOrigin} />;
}

export default SubscriptionOrderStatusPopUp;
