import { MouseEvent } from 'react';
import { primaryMerBlue } from 'styles/variables';
import { useTranslation } from 'react-i18next';
import IconButton from './IconButton';
import InfoIcon from 'assets/icons/info_solid.svg?react';
import styled from 'styled-components';

const StyledInfoIconButton = styled(IconButton)`
    border-radius: 50%;
    cursor: pointer;
    color: ${primaryMerBlue};
`;

type InfoIconButtonProp = {
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    dataTestId?: string;
    disabled?: boolean;
};

function InfoIconButton({ onClick, dataTestId, disabled = false }: InfoIconButtonProp): JSX.Element {
    const { t } = useTranslation();
    const onClickHandler = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        if (onClick) {
            onClick(event);
        }
    };
    return (
        <StyledInfoIconButton
            disabled={disabled}
            data-testid={dataTestId ?? 'infoButton'}
            onClick={onClickHandler}
            aria-label={t('general.readMore')}
        >
            <InfoIcon />
        </StyledInfoIconButton>
    );
}

export default InfoIconButton;
