import { EConnectorStatus, IProperty } from 'types/facility';
import { batchArray, getSitesFromPropertyList } from 'utils/connectorStatus';
import { useEffect, useRef } from 'react';
import { useQueries } from '@tanstack/react-query';
import ApiError from 'classes/ApiError';
import useApi from 'hooks/useApi';
import useLocalState from './useLocalState';

export type Connector = {
    evseId: string;
    status: string;
};

export type UseConnectorStatusResponse = {
    connectors?: Connector[];
    isLoading: boolean;
    error?: ApiError;
};

export type ConnectorStatusResult = {
    connectors?: Connector[];
};

type UseConnectorStatusBodyProps = {
    siteIds: string[];
};

const useConnectorStatus = (properties: IProperty[]): UseConnectorStatusResponse => {
    const {
        actions: { setConnectorState },
    } = useLocalState();

    const { post } = useApi<ConnectorStatusResult, UseConnectorStatusBodyProps>();
    const url = `/v1/connector-status`;

    const sitesBatches = batchArray(getSitesFromPropertyList(properties), 20);

    const fetcher: (siteIds: string[]) => Promise<ConnectorStatusResult> = (siteIds) =>
        post(url, {
            siteIds,
        });

    const results = useQueries({
        queries: sitesBatches.map((sitesBatch) => ({
            queryKey: [url, sitesBatch.join(',')],
            queryFn: () => fetcher(sitesBatch),
        })),
    });

    const isLoading = results.some((result) => result.isFetching);

    const processedUpdatesRef = useRef(new Set<string>());

    useEffect(() => {
        results.forEach((query, index) => {
            if (query.isSuccess && !processedUpdatesRef.current.has(sitesBatches[index].toString())) {
                query.data.connectors?.forEach((connector) => {
                    setConnectorState({ evseId: connector.evseId, status: connector.status as EConnectorStatus });
                    processedUpdatesRef.current.add(sitesBatches[index].toString());
                });
            }
        });
    }, [results, setConnectorState, sitesBatches]);

    return {
        isLoading,
    };
};
export default useConnectorStatus;
