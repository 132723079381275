import * as yup from 'yup';
import { Divider } from 'components/elements/Divider';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { readingWidth, spaceM, spaceS } from 'styles/variables';
import LinkText from 'components/clickables/LinkText';
import ToggleInput from 'components/forms/ToggleInput';
import styled from 'styled-components';
import useBUContent from 'hooks/useBUContent';

const Paragraph = styled.p`
    margin-bottom: ${spaceS};
    padding-top: ${spaceS};
    width: calc(${readingWidth} + ${spaceM});
`;

const ToggleContainer = styled.section`
    width: calc(${readingWidth} + ${spaceM});
`;

export type ConsentFormProps = {
    termsAndConditionsRegister: UseFormRegisterReturn;
    termsAndConditionsError?: FieldError;
    hidePrivacyPolicy?: boolean;
};

export const ConsentFormSchema = yup.object().shape({
    termsAndConditionsConsent: yup
        .boolean()
        .required('form.input.termsAndConditionsConsent.required')
        .oneOf([true], 'form.input.termsAndConditionsConsent.required'),
});

function ConsentForm({
    termsAndConditionsRegister,
    termsAndConditionsError,
    hidePrivacyPolicy = false,
}: ConsentFormProps): JSX.Element {
    const { buContent } = useBUContent();

    return (
        <>
            {hidePrivacyPolicy ? null : (
                <Paragraph>
                    <Trans
                        i18nKey="general.privacyPolicy"
                        components={{
                            link1: <LinkText to={buContent.privacyPolicyLink} />,
                        }}
                    />
                </Paragraph>
            )}
            <Divider />
            <ToggleContainer>
                <ToggleInput
                    required
                    dataTestId="termsAndConditionsConsent"
                    label={
                        <Trans
                            i18nKey="form.input.termsAndConditionsConsent.label"
                            components={{
                                link1: <LinkText to={buContent.termsOfSalesLink} />,
                            }}
                        />
                    }
                    {...termsAndConditionsRegister}
                    fieldError={termsAndConditionsError}
                />
            </ToggleContainer>
        </>
    );
}

export default ConsentForm;
