import { useQuery } from '@tanstack/react-query';
import ApiError from 'classes/ApiError';
import useApi from 'hooks/useApi';

export type UseCo2Response = {
    co2Data?: UseCo2Result;
    isLoading: boolean;
    error?: ApiError;
};
type UseCo2Props = {
    businessIds: string[];
};
export type UseCo2Result = {
    homeCO2Saving: number;
    publicCO2Saving: number;
    internalCO2Saving: number;
    savedTrees: number;
};
const useCo2 = ({ businessIds }: UseCo2Props): UseCo2Response => {
    const { post } = useApi<UseCo2Result, UseCo2Props>();
    const url = `/v1/billing-transactions/co2-savings`;
    const fetcher: () => Promise<UseCo2Result> = () => post(url, { businessIds });
    const {
        data: co2Data,
        isFetching: isLoading,
        error,
    } = useQuery<UseCo2Result, ApiError>({
        queryKey: [`${url}?${businessIds}`],
        queryFn: fetcher,
        enabled: !!businessIds,
    });

    return {
        co2Data,
        isLoading,
        error: error ?? undefined,
    };
};
export default useCo2;
