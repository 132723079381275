import { ETrackingEvent, IPopupTracking } from 'types/tracking';
import { borderRadiusS, primaryMerBlue } from 'styles/variables';
import { useTranslation } from 'react-i18next';
import InfoIcon from 'assets/icons/question_mark_solid.svg?react';
import ReactGA from 'react-ga4';
import Tippy from '@tippyjs/react';
import styled from 'styled-components';

const TippyTool = styled.div`
    display: flex;
    .tippy {
        box-shadow: rgba(0, 0, 0, 0.08) 0px 0.5rem 1.5rem;
    }

    .tippyButton {
        background: transparent;
        border: none;
        border-radius: ${borderRadiusS};
        line-height: 0;
        width: 2rem;
        height: 2rem;
        color: ${primaryMerBlue};
    }
`;

type PopUpProp = {
    icon?: React.ReactNode;
    content?: React.ReactNode | React.ReactNode[];
    dataTestId?: string;
    gaTracking?: IPopupTracking;
};

function PopUp({ icon, content, dataTestId, gaTracking }: PopUpProp): JSX.Element {
    const { t } = useTranslation();
    return (
        <TippyTool>
            <Tippy
                hideOnClick="toggle"
                arrow={false}
                theme="light"
                placement="bottom-end"
                className="tippy"
                allowHTML
                maxWidth="26rem"
                content={content}
                onShow={() => {
                    if (gaTracking) {
                        ReactGA.event(ETrackingEvent.INFO_POPUP, {
                            type: gaTracking.type,
                            origin: gaTracking.origin,
                        });
                    }
                }}
            >
                <button
                    type="button"
                    className="tippyButton"
                    data-testid={dataTestId ?? 'popupButton'}
                    aria-label={t('general.readMore')}
                >
                    {icon ?? <InfoIcon data-testid="defaultIcon" />}
                </button>
            </Tippy>
        </TippyTool>
    );
}

export default PopUp;
