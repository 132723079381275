import { EExportFileType, EPageErrorType, ESortingOrder } from 'types';
import { ESubscriptionSortingColumns, ESubscriptionStatus } from 'types/subscription';
import { ETrackingEvent, ETrackingOrigin } from 'types/tracking';
import { PAGE_SIZE } from 'constants/general';
import { PATH } from 'constants/paths';
import { darkGrey, spaceM, spaceS } from 'styles/variables';
import { formatDate } from 'utils/formatDate';
import { t } from 'i18next';
import { useCountrySpecificContent } from 'hooks/useCountrySpecificContent';
import { useEffect, useRef, useState } from 'react';
import { useExport } from 'hooks/useExport';
import { useSearchParams } from 'react-router-dom';
import ErrorPage from 'pages/ErrorPage';
import ExportButton from 'components/clickables/ExportButton';
import GridList, { GridListHeader, GridListItem, Header } from 'components/lists/GridList';
import ListFooter from 'components/lists/ListFooter';
import ReactGA from 'react-ga4';
import SearchInput from 'components/forms/SearchInput';
import SmallLinkButton from 'components/clickables/SmallLinkButton';
import SortableColumnHeader from 'components/lists/SortableColumnHeader';
import StatusPill from 'components/info/StatusPill';
import SubscriptionStatusPopUp from './SubscriptionStatusPopUp';
import TextEllipsisComponent, { TextEllipsis } from 'components/info/TextEllipsisComponent';
import styled from 'styled-components';
import useBusinessId from 'hooks/useBusinessId';
import useSubscriptions from 'hooks/useSubscriptions';
import useSubscriptionsExport from 'hooks/useSubscriptionsExport';

const UtilityBar = styled.div`
    display: flex;
    gap: ${spaceS};
    margin-bottom: ${spaceM};
`;

const gridColumn = '7rem minmax(3rem, 1fr) 7rem minmax(3rem, 1fr) 9.375rem minmax(3rem, 1fr) 4.5rem';

const StyledTextEllipsis = styled(TextEllipsis)`
    color: ${darkGrey};
`;

function SubscriptionList(): JSX.Element {
    const { activeParentBusinessId } = useBusinessId();

    const [searchParams, setSearchParams] = useSearchParams();
    const [sorting, setSorting] = useState({
        sortColumn: ESubscriptionSortingColumns.CREATION_TIME,
        sortDirection: ESortingOrder.DESC,
    });
    const searchTab = searchParams.get('tab');
    const searchPageNumber = searchParams.get('pageNumber');
    const searchTerm = searchParams.get('searchTerm');

    const querySearchParam = searchParams;

    const firstRender = useRef(searchTerm);
    const [pageNumber, setPageNumber] = useState<number>(searchPageNumber ? Number(searchPageNumber) : 1);

    useEffect(() => {
        if (searchTab != null) {
            searchParams.delete('tab');
            setSearchParams(searchParams);
            setPageNumber(1);
        }
    }, [searchTab, searchParams, setSearchParams]);

    useEffect(() => {
        if (pageNumber === 1) {
            querySearchParam.set('pageNumber', '1');
        } else {
            querySearchParam.set('pageNumber', String(pageNumber));
        }
        setSearchParams(querySearchParam);
    }, [pageNumber, querySearchParam, setSearchParams]);

    useEffect(() => {
        if (searchTerm !== firstRender.current) {
            setPageNumber(1);
        }
    }, [searchTerm]);

    const [fileType, setFileType] = useState<EExportFileType>(EExportFileType.EXCEL);

    const subscriptionsQueryData = {
        parentBusinessId: activeParentBusinessId,
        pageNumber: pageNumber - 1,
        pageSize: PAGE_SIZE,
        searchTerm: searchTerm ?? '',
        sortColumn: sorting.sortColumn,
        sortDirection: sorting.sortDirection,
        fileType,
    };

    const {
        subscriptionList,
        error: errorSubscriptions,
        isLoading,
        mutateSubscriptions,
    } = useSubscriptions(subscriptionsQueryData);
    const getExport = useSubscriptionsExport(subscriptionsQueryData);

    const haveSubscriptions = subscriptionList && subscriptionList.subscriptions.length >= 1;
    const { startExport, somethingToExport } = useExport(haveSubscriptions);

    const handlePagination = (page: number): void => {
        setPageNumber(page);
    };

    const getSortingDirection = (column: ESubscriptionSortingColumns): ESortingOrder => {
        const direction = sorting.sortColumn === column ? sorting.sortDirection : undefined;
        return direction as ESortingOrder;
    };

    const handleOnSortChange = (sortColumn: ESubscriptionSortingColumns, sortDirection: ESortingOrder): void => {
        setSorting({ sortColumn, sortDirection });
        mutateSubscriptions();
    };

    const { locale } = useCountrySpecificContent();

    return (
        <>
            <UtilityBar>
                <SearchInput placeholder={t('subscription.subscriptionList.searchPlaceHolder')} />
                <ExportButton
                    setFileType={setFileType}
                    isLoading={getExport.isPending}
                    getExport={() => {
                        ReactGA.event(ETrackingEvent.EXPORT_FILE, {
                            origin: ETrackingOrigin.SUBSCRIPTION,
                        });
                        startExport(() => getExport.mutateAsync(), haveSubscriptions);
                    }}
                    showMessage={!somethingToExport}
                />
            </UtilityBar>
            <GridList
                gridColumns={gridColumn}
                dataTestId="subscriptionsList"
                showEmptyListMessage={subscriptionList?.subscriptions.length === 0 && !isLoading}
                isLoading={!haveSubscriptions && isLoading}
            >
                <GridListHeader>
                    <SortableColumnHeader
                        sortingDirection={getSortingDirection(ESubscriptionSortingColumns.STATUS)}
                        onSortChange={(direction) =>
                            handleOnSortChange(ESubscriptionSortingColumns.STATUS, direction as ESortingOrder)
                        }
                        infoButton={<SubscriptionStatusPopUp gaTrackingOrigin={ETrackingOrigin.SUBSCRIPTION} />}
                        text={t('general.status')}
                    />
                    <Header>{t('general.name')}</Header>
                    <Header>{t('general.licencePlate')}</Header>
                    <Header>{t('general.reference')}</Header>
                    <SortableColumnHeader
                        sortingDirection={getSortingDirection(ESubscriptionSortingColumns.CREATION_TIME)}
                        onSortChange={(direction) =>
                            handleOnSortChange(ESubscriptionSortingColumns.CREATION_TIME, direction as ESortingOrder)
                        }
                        text={t('general.startDate')}
                    />
                    <Header>{t('general.subscription')}</Header>
                    <span />
                </GridListHeader>
                {haveSubscriptions && !errorSubscriptions && (
                    <>
                        {subscriptionList.subscriptions.map((sub) => (
                            <GridListItem key={sub.id}>
                                <StatusPill status={sub.status as ESubscriptionStatus} />
                                {!sub.driverName ? (
                                    <StyledTextEllipsis>{t('subscription.unpersonalised')}</StyledTextEllipsis>
                                ) : (
                                    <TextEllipsisComponent tooltipText={sub.driverName}>
                                        {sub.driverName}
                                    </TextEllipsisComponent>
                                )}
                                <TextEllipsis>{sub.licencePlate}</TextEllipsis>
                                <TextEllipsisComponent tooltipText={sub.reference}>
                                    {sub.reference}
                                </TextEllipsisComponent>
                                <p>{formatDate(sub.startDate, locale)}</p>
                                <TextEllipsisComponent tooltipText={sub.productType}>
                                    {sub.productType}
                                </TextEllipsisComponent>
                                <SmallLinkButton
                                    to={`${PATH.SUBSCRIPTION_OVERVIEW}/${PATH.SUBSCRIPTION_DETAILS}?id=${sub.id}&businessId=${sub.businessId}`}
                                    text={t('general.button.view')}
                                />
                            </GridListItem>
                        ))}
                    </>
                )}
                {errorSubscriptions && <ErrorPage type={EPageErrorType.TEMPORARY} displayBorder={false} />}
            </GridList>
            {haveSubscriptions && !isLoading && (
                <ListFooter data={subscriptionList} handlePagination={handlePagination} pageNumber={pageNumber} />
            )}
        </>
    );
}

export default SubscriptionList;
