type PagesToDisplayProps = {
    currentPage: number;
    totalPages: number;
    range?: number;
};

export function pagesToDisplay({ currentPage, totalPages, range = 5 }: PagesToDisplayProps): number[] {
    // Number of pages to display
    const length = range > totalPages ? totalPages : range;

    let startPage = currentPage - Math.floor(length / 2);

    // Check for minumim boundary
    startPage = Math.max(startPage, 1);

    // Check for maximum boundary
    startPage = Math.min(startPage, 1 + totalPages - length);

    return Array.from({ length }, (el, i) => startPage + i);
}
