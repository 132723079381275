import {
    Bar,
    CartesianGrid,
    BarChart as Chart,
    Label,
    Legend,
    ResponsiveContainer,
    Tooltip,
    TooltipProps,
    XAxis,
    YAxis,
} from 'recharts';
import { Key, useMemo } from 'react';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import {
    borderRadiusS,
    darkGrey,
    font,
    listHoverBackgroundColor,
    menuBoxShadow,
    spaceS,
    spaceXs,
    spaceXxs,
} from 'styles/variables';
import styled from 'styled-components';

const Container = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    .barChart {
        font-size: ${font.size.s};
    }
`;

const LegendList = styled.ul`
    display: flex;
    justify-content: flex-end;
    gap: ${spaceS};
    position: absolute;
    right: 0;
`;
const LegendText = styled.li<{ color: string }>`
    color: ${font.color.default};
    display: flex;
    align-items: center;
    gap: ${spaceXxs};
    &::before {
        display: inline-block;
        content: ' ';
        width: 6px;
        height: 6px;
        background: ${({ color }) => color};
    }
`;

const TooltipContainer = styled.div`
    background: white;
    border-radius: ${borderRadiusS};
    padding: ${spaceS};
    box-shadow: ${menuBoxShadow};
    color: ${font.color.default};
    min-width: 11.25rem;
    h1 {
        font-size: ${font.size.s};
        line-height: ${font.lineHeight.s};
        margin-bottom: ${spaceXs};
    }
    li {
        display: flex;
        gap: ${spaceS};
        align-items: center;
        justify-content: space-between;
        margin-bottom: ${spaceXxs};
    }
`;

export interface BarItem {
    dataKey: string;
    color: string;
    legend: string;
}

type BarChartProps = {
    xAxisLabel: string;
    chartData: unknown[];
    barItemConfig: BarItem[];
    className?: string;
};

export const renderTooltipContent = (
    { active, payload, label }: TooltipProps<ValueType, NameType>,
    barItemConfig: BarItem[],
): JSX.Element | null => {
    if (active && payload?.length) {
        return (
            <TooltipContainer data-testid="barChartTooltip">
                <h1 className="label">{label}</h1>
                <ol>
                    {payload.map((item) => {
                        const tooltipLabel = barItemConfig.find((configItem) => configItem.dataKey === item.dataKey);

                        return (
                            <li key={item.name}>
                                <span>{tooltipLabel?.legend}:</span> <span>{item.value}</span>
                            </li>
                        );
                    })}
                </ol>
            </TooltipContainer>
        );
    }
    return null;
};

function BarChart({ xAxisLabel, chartData, barItemConfig, className = '' }: BarChartProps): JSX.Element {
    const renderLegend = useMemo(() => {
        return (
            <LegendList>
                {barItemConfig.map((item: { color: string; dataKey: Key | null | undefined; legend: string }) => (
                    <LegendText color={item.color} key={item.dataKey}>
                        {item.legend}
                    </LegendText>
                ))}
            </LegendList>
        );
    }, [barItemConfig]);

    return (
        <Container data-testid="barChart" className={className}>
            <ResponsiveContainer width="100%" height="100%">
                <Chart width={500} height={300} data={chartData} className="barChart" margin={{ left: 0, top: 0 }}>
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis dataKey="name" axisLine={false} tickLine={false} stroke={darkGrey} />
                    <YAxis axisLine={false} tickLine={false} stroke={darkGrey} width={90}>
                        <Label value={xAxisLabel} offset={28} position="top" fill={darkGrey} />
                    </YAxis>
                    <Tooltip
                        cursor={{ fill: listHoverBackgroundColor }}
                        content={(props) => renderTooltipContent(props, barItemConfig)}
                    />
                    <Legend align="right" verticalAlign="top" height={40} content={renderLegend} />
                    {barItemConfig.map((item, index) => (
                        <Bar
                            key={item.dataKey}
                            dataKey={item.dataKey}
                            fill={item.color}
                            id={`${item.dataKey}_${index}`}
                        />
                    ))}
                </Chart>
            </ResponsiveContainer>
        </Container>
    );
}

export default BarChart;
