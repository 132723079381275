import { Business } from 'types/business';
import { REACT_QUERY_ONE_CALL_OPTIONS } from 'constants/general';
import { useQuery } from '@tanstack/react-query';
import ApiError from 'classes/ApiError';
import useApi from './useApi';

type usePermissionsResult = {
    businesses: Business[];
    isLoading: boolean;
    error?: ApiError;
};
type BusinessFeaturesResult = {
    businesses: Business[];
};

const useBusinesses = (): usePermissionsResult => {
    const { get } = useApi<BusinessFeaturesResult>();

    const url = `/v1/businesses`;
    const fetcher: () => Promise<BusinessFeaturesResult> = () => get(url);

    const {
        data,
        isFetching: isLoading,
        error,
    } = useQuery<BusinessFeaturesResult, ApiError>({
        queryKey: [url],
        queryFn: fetcher,
        ...REACT_QUERY_ONE_CALL_OPTIONS,
    });

    const businesses = data?.businesses ?? [];

    return {
        businesses,
        isLoading,
        error: error ?? undefined,
    };
};

export default useBusinesses;
