import { ChargeDetailsResult } from 'types/subscription';
import { PAGE_SIZE } from 'constants/general';
import { endOfDay, parse } from 'date-fns';
import { useCountrySpecificContent } from './useCountrySpecificContent';
import { useQuery } from '@tanstack/react-query';
import ApiError from 'classes/ApiError';
import useApi from './useApi';

export type SubscriptionChargeDetailsResponse = {
    chargeDetailsList?: ChargeDetailsResult;
    isLoading: boolean;
    error?: ApiError;
};

type GetChargingHistoryParams = {
    businessIds: string[];
    pageSize?: number;
    pageNumber?: number;
    searchTerm?: string;
    subscriptionTypes?: string[];
    dateFrom?: string;
    dateTo?: string;
};
type ChargeDetailsPostProps = {
    businessIds: string[];
    subscriptionTypes?: string[];
};

function useSubscriptionChargeDetails(prop: GetChargingHistoryParams): SubscriptionChargeDetailsResponse {
    const { businessIds, pageNumber = 0, pageSize = PAGE_SIZE, searchTerm, dateFrom, dateTo, subscriptionTypes } = prop;
    const { dateFormat } = useCountrySpecificContent();

    const paramsArray: string[][] = [];
    paramsArray.push(['pageSize', pageSize.toString()]);
    paramsArray.push(['pageNumber', pageNumber.toString()]);

    if (searchTerm) {
        paramsArray.push(['searchTerm', searchTerm]);
    }
    if (dateFrom && dateTo) {
        paramsArray.push(['dateFrom', parse(dateFrom, dateFormat, new Date()).toISOString()]);
        paramsArray.push(['dateTo', endOfDay(parse(dateTo, dateFormat, new Date())).toISOString()]);
    }

    const { post } = useApi<ChargeDetailsResult, ChargeDetailsPostProps>();
    const urlParams = new URLSearchParams(paramsArray).toString();
    const url = `/v1/billing-transactions?${urlParams}`;
    const fetcher: () => Promise<ChargeDetailsResult> = () =>
        post(url, {
            businessIds,
            ...(!!subscriptionTypes?.length && {
                subscriptionTypes,
            }),
        });

    const {
        data: chargeDetailsList,
        isFetching: isLoading,
        error,
    } = useQuery<ChargeDetailsResult, ApiError>({
        queryKey: [`${url}?${businessIds}`],
        queryFn: fetcher,
        enabled: !!businessIds.length,
    });

    return {
        chargeDetailsList,
        isLoading,
        error: error ?? undefined,
    };
}

export default useSubscriptionChargeDetails;
