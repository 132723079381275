export interface IProperty {
    name: string;
    chargerOwnerBusinessIds: string[];
    sites: ISite[];
}

export interface ISite {
    name: string;
    id: string;
    chargers: ICharger[];
}

export interface ICharger {
    businessId: string;
    name: string;
    identityKey: string;
    model: string;
    equipmentId: string;
    serialNumber: string;
    connectors: IConnector[];
}

export interface IConnector {
    connectorId: string;
    evseId: string;
    type: string;
}

export enum EConnectorStatus {
    AVAILABLE = 'AVAILABLE',
    OCCUPIED = 'OCCUPIED',
    FAULTED = 'FAULTED',
    UNAVAILABLE = 'UNAVAILABLE',
    UNKNOWN = 'UNKNOWN',
}
export interface IConnectorStatus {
    evseId: string;
    status: EConnectorStatus;
}
