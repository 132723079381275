import { ButtonVariant } from 'types';
import { ETrackingEvent } from 'types/tracking';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/clickables/Button';
import ReactGA from 'react-ga4';
import useAuthentication from 'hooks/useAuthentication';

type LogoutButtonProps = {
    className?: string;
    variant?: ButtonVariant;
};

function LogoutButton({ className, variant }: LogoutButtonProps): JSX.Element {
    const { logoutRedirectRegular } = useAuthentication();
    const { t } = useTranslation();

    const logoutAndReturn = useCallback(() => {
        ReactGA.event(ETrackingEvent.LOGOUT);
        logoutRedirectRegular(true);
    }, [logoutRedirectRegular]);

    return (
        <Button dataTestId="userMenuLogoutButton" onClick={logoutAndReturn} className={className} variant={variant}>
            {t('general.logout')}
        </Button>
    );
}

export default LogoutButton;
