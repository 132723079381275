import { BU_CODE } from 'constants/bu';
import { CountryContent, DE_CONTENT, SE_CONTENT } from 'constants/countrySettings';
import useToken from './useToken';

export const useCountrySpecificContent = (): CountryContent => {
    const { businessUnitCode } = useToken();
    if (businessUnitCode === BU_CODE.DE) {
        return DE_CONTENT;
    }
    return SE_CONTENT;
};
