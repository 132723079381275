import * as yup from 'yup';
import { ButtonSize, ButtonType, ButtonVariant, EToastType } from 'types';
import { DetailsPageList } from 'components/lists/DetailsPageList';
import { EBusinessFeature } from 'types/business';
import { ESubscriptionStatus, Subscription } from 'types/subscription';
import { ETrackingAction, ETrackingEvent, ETrackingOrigin } from 'types/tracking';
import { EditDetailsSection } from 'components/elements/EditDetailsSection';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UseMutationResult } from '@tanstack/react-query';
import { formatDate } from 'utils/formatDate';
import { spaceS } from 'styles/variables';
import { useCountrySpecificContent } from 'hooks/useCountrySpecificContent';
import { useState } from 'react';
import { useToast } from 'contexts/Toast';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import ApiError from 'classes/ApiError';
import Button from 'components/clickables/Button';
import ReactGA from 'react-ga4';
import SectionPanel from 'components/elements/SectionPanel';
import StatusTag from 'components/visuals/StatusTag';
import SubscriptionStatusPopUp from '../SubscriptionStatusPopUp';
import TextInput from 'components/forms/TextInput';
import styled from 'styled-components';
import useAuthorization from 'hooks/useAuthorization';

const ButtonContainer = styled.section`
    display: flex;
    gap: ${spaceS};
    margin-top: ${spaceS};
`;

const StyledButton = styled(Button)`
    justify-self: end;
`;

const LicencePlateInput = styled(TextInput)`
    max-width: 8.75rem;
`;
const Rfid = styled.dd`
    span {
        display: block;
    }
`;

type SubscriptionDetailsPanelProps = {
    subscription: Subscription;
    patchSubscription: UseMutationResult<Subscription, ApiError, Partial<Subscription>>;
};

const UpdateSubscriptionSchema = (
    licencePlateRegExp: RegExp,
): yup.ObjectSchema<Pick<Subscription, 'licencePlate' | 'reference'>> =>
    yup.object().shape({
        licencePlate: yup
            .string()
            .required('form.input.licencePlate.required')
            .matches(licencePlateRegExp, { message: 'form.input.licencePlate.validation' }),
        reference: yup.string().required('form.input.reference.required'),
    });

function SubscriptionDetailsPanel({ subscription, patchSubscription }: SubscriptionDetailsPanelProps): JSX.Element {
    const { t } = useTranslation();
    const { addToast } = useToast();
    const { checkAuthorizationFeature } = useAuthorization();
    const editAllowed =
        checkAuthorizationFeature(EBusinessFeature.SUBSCRIPTION_MANAGEMENT_EDIT) && subscription.isActive === true;

    const [editMode, setEditMode] = useState<boolean>(false);
    const { licencePlateRegExp, locale, licencePlateMaxLength, licencePlateMinLength } = useCountrySpecificContent();

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        reset,
    } = useForm<Partial<Subscription>>({
        mode: 'onBlur',
        resolver: yupResolver(UpdateSubscriptionSchema(licencePlateRegExp) as yup.ObjectSchema<Partial<Subscription>>),
        shouldUnregister: false,
        defaultValues: {
            licencePlate: subscription.licencePlate,
            reference: subscription.reference,
        },
    });

    const onSubmit: SubmitHandler<Partial<Subscription>> = async (data): Promise<void> => {
        ReactGA.event(ETrackingEvent.EDIT, {
            origin: ETrackingOrigin.SUBSCRIPTION_DETAILS,
            action: ETrackingAction.SUBMIT,
        });
        try {
            if (isDirty) {
                await patchSubscription.mutateAsync(data);
                addToast({ message: t('subscription.details.successMessage'), type: EToastType.SUCCESS });
                setEditMode(false);
            }
        } catch (e) {
            if (e instanceof ApiError) {
                if (e.temporary) {
                    addToast({ message: t('general.errorToast'), type: EToastType.ERROR });
                } else {
                    addToast({ message: t('subscription.details.errorMessage'), type: EToastType.ERROR });
                }
            }
        }
    };
    const onCancel = (): void => {
        ReactGA.event(ETrackingEvent.EDIT, {
            origin: ETrackingOrigin.SUBSCRIPTION_DETAILS,
            action: ETrackingAction.CANCEL,
        });
        setEditMode(false);
        reset(subscription);
    };

    return (
        <SectionPanel
            title={t('subscription.details.detailsSectionTitle')}
            dataTestId="sectionSubscriptionDetails"
            button={
                !editMode &&
                editAllowed && (
                    <StyledButton
                        dataTestId="editSub"
                        variant={ButtonVariant.SECONDARY}
                        size={ButtonSize.SMALL}
                        onClick={() => {
                            ReactGA.event(ETrackingEvent.EDIT, {
                                origin: ETrackingOrigin.SUBSCRIPTION_DETAILS,
                                action: ETrackingAction.EDIT_MODE,
                            });
                            setEditMode(true);
                        }}
                    >
                        {t('general.button.edit')}
                    </StyledButton>
                )
            }
        >
            <DetailsPageList>
                <dt>{t('general.status')}</dt>
                <dd>
                    <StatusTag
                        status={subscription.isActive ? ESubscriptionStatus.ACTIVE : ESubscriptionStatus.INACTIVE}
                        infoButton={<SubscriptionStatusPopUp gaTrackingOrigin={ETrackingOrigin.SUBSCRIPTION_DETAILS} />}
                    />
                </dd>
                <dt>{t('general.startDate')}</dt>
                <dd>{formatDate(subscription.startDate, locale)}</dd>
                {subscription.endDate && (
                    <>
                        <dt>{t('general.endDate')}</dt>
                        <dd>{formatDate(subscription.endDate, locale)}</dd>
                    </>
                )}
                {subscription.driver && (
                    <>
                        <dt>{t('general.email')}</dt>
                        <dd>{subscription.driver?.email}</dd>
                        <dt>{t('general.name')}</dt>
                        <dd>{`${subscription.driver?.firstName} ${subscription.driver?.lastName}`}</dd>
                    </>
                )}
                <dt>{t('general.type')}</dt>
                <dd>{subscription.productType}</dd>
                <dt>{t('general.rfid')}</dt>
                <Rfid>{subscription.rfids?.map((rfid) => <span key={rfid}>{rfid}</span>)}</Rfid>
                <dt>{t('general.organisation')}</dt>
                <dd>{subscription.businessName}</dd>
                <dt>{t('general.licencePlate')}</dt>
                <dd>{subscription.licencePlate}</dd>
                <dt>{t('general.reference')}</dt>
                <dd>{subscription.reference}</dd>
            </DetailsPageList>

            {editAllowed && editMode && (
                <EditDetailsSection>
                    <form title="form" onSubmit={handleSubmit(onSubmit)} noValidate>
                        <LicencePlateInput
                            maxLength={licencePlateMaxLength}
                            minLength={licencePlateMinLength}
                            label={t('form.input.licencePlate.label')}
                            required
                            fieldError={errors.licencePlate}
                            {...register('licencePlate', { required: true })}
                        />
                        <TextInput
                            maxLength={25}
                            required
                            label={t('form.input.reference.label')}
                            fieldError={errors.reference}
                            {...register('reference', { required: true })}
                        />
                        <ButtonContainer>
                            <Button
                                dataTestId="subscriptionDetailsPanelCancel"
                                type={ButtonType.BUTTON}
                                variant={ButtonVariant.SECONDARY}
                                onClick={onCancel}
                            >
                                {t('general.cancel')}
                            </Button>
                            <Button
                                dataTestId="subscriptionDetailsPanelSave"
                                type={ButtonType.SUBMIT}
                                isLoading={patchSubscription.isPending}
                            >
                                {t('general.save')}
                            </Button>
                        </ButtonContainer>
                    </form>
                </EditDetailsSection>
            )}
        </SectionPanel>
    );
}

export default SubscriptionDetailsPanel;
