import { IProperty } from 'types/facility';

export const getListWithFilteredChargersByBusinessId = (
    propertyList: IProperty[],
    selectedOrganisation: string,
): IProperty[] => {
    return propertyList.map((property) => {
        // Filter sites with chargers matching the selected organization
        const filteredSites = property.sites
            .map((site) => {
                const filteredChargers = site.chargers.filter((charger) => charger.businessId === selectedOrganisation);
                return { ...site, chargers: filteredChargers };
            })
            .filter((site) => site.chargers.length > 0); // Keep only sites with matching chargers

        return { ...property, sites: filteredSites };
    });
};
