import { Link } from 'react-router-dom';
import { borderRadiusM, font, lightBlue, mediumGrey, primaryMerBlue, spaceS, white } from 'styles/variables';
import styled from 'styled-components';

const StyledLink = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${white};
    font-size: ${font.size.s};
    text-decoration: none;
    color: ${primaryMerBlue};
    border: 2px solid ${primaryMerBlue};
    border-radius: ${borderRadiusM};
    cursor: pointer;
    height: 2rem;
    padding: 0 ${spaceS};
    :hover {
        background: ${lightBlue};
        border: 1px solid ${primaryMerBlue};
    }
    :active {
        background: ${lightBlue};
    }
    :disabled {
        color: ${mediumGrey};
    }
`;

type LinkButtonProps = {
    to: string;
    text: string;
    className?: string;
};

function SmallLinkButton({ to, text, className }: LinkButtonProps): JSX.Element {
    return (
        <StyledLink className={className} to={to}>
            {text}
        </StyledLink>
    );
}

export default SmallLinkButton;
