/* eslint-disable @typescript-eslint/no-explicit-any */

type useBroadcastResult = {
    close: () => void;
    registerOnMessage: (callback: (msg: any) => void) => void;
    postMessage: (message: string) => void;
};

const logoutChannel = new BroadcastChannel('logout');

const useLogoutBroadcast: () => useBroadcastResult = () => {
    const close: () => void = () => {
        logoutChannel.close();
    };
    const registerOnMessage: (callback: (msg: any) => void) => void = (callback) => {
        logoutChannel.onmessage = (msg) => callback(msg);
    };
    const postMessage: (message: string) => void = (message) => {
        logoutChannel.postMessage(message);
    };

    return {
        close,
        registerOnMessage,
        postMessage,
    };
};

export default useLogoutBroadcast;
