import { DELIMITER_REGEX, SPECIAL_CHARACTERS, SPLIT_LINES, SUBSCRIPTION_FILE_COLUMNS } from 'constants/fileImport';
import { EDeliveryOption, FileTypes } from 'types';
import { ESubscriptionBulkError } from 'types/subscription';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import readXlsxFile from 'read-excel-file';

export type FileReaderResult = {
    error: string | null;
    result: BulkSubscription[];
};

export type BulkSubscription = {
    subscriptionType: string;
    email: string;
    firstName: string;
    lastName: string;
    licencePlate: string;
    reference: string;
    organisation: string;
    deliveryOption: string;
    errorType?: ESubscriptionBulkError;
};

type FixedArray<T, L extends number> = Array<T> & {
    length: L;
};

type FixedStringArray = FixedArray<string, 8>;

const removeSpecialCharacters = (text: string): string => {
    return text ? text.toString().replace(SPECIAL_CHARACTERS, '') : '';
};

const splitDelimiter = (row: string): FixedStringArray => {
    return row.split(DELIMITER_REGEX) as FixedStringArray;
};

const checkIfRowIsEmpty = (row: string[]): boolean => {
    return row.every((value) => !value);
};

const useSubscriptionFileReader = (file: File | null): FileReaderResult => {
    const { t } = useTranslation();
    const [error, setError] = useState<string | null>(null);
    const [result, setResult] = useState<BulkSubscription[]>([]);

    const convertFixedArrayToBulkSubscription = useCallback((row: FixedStringArray): BulkSubscription => {
        const [subscriptionType, email, firstName, lastName, licencePlate, reference, organisation, deliveryOption] =
            row.map((item) => (item ? item.trim() : ''));
        return {
            subscriptionType,
            email,
            firstName,
            lastName,
            licencePlate,
            reference,
            organisation,
            deliveryOption: deliveryOption ? EDeliveryOption.HOME : EDeliveryOption.MAIN,
        };
    }, []);

    const processRows = useCallback(
        (rows: string[][], reject: (reason: Error) => void, resolve: (arg0: BulkSubscription[]) => void) => {
            if (rows.length <= 1) {
                reject(new Error(t('widget.browseFile.emptyFile')));
                return;
            }

            const firstRow = rows[0];

            if (SUBSCRIPTION_FILE_COLUMNS !== firstRow.length) {
                reject(new Error(t('widget.browseFile.invalidFormat')));
                return;
            }

            const subscriptions = rows
                .slice(1)
                .map((row) => {
                    const sanitizedRow = row.map((value) => removeSpecialCharacters(value).trim());
                    if (checkIfRowIsEmpty(sanitizedRow)) {
                        return false;
                    }

                    if (SUBSCRIPTION_FILE_COLUMNS !== row.length) {
                        reject(new Error(t('widget.browseFile.invalidFormat')));
                        return false;
                    }

                    return convertFixedArrayToBulkSubscription(sanitizedRow as FixedStringArray);
                })
                .filter(Boolean) as BulkSubscription[];

            if (!subscriptions.length) {
                reject(new Error(t('widget.browseFile.emptyFile')));
                return;
            }

            resolve(subscriptions);
        },
        [convertFixedArrayToBulkSubscription, t],
    );

    const processCSV = useCallback(
        (importedFile: File, reject: (reason: Error) => void, resolve: (arg0: BulkSubscription[]) => void) => {
            const reader = new FileReader();

            reader.onload = (e: ProgressEvent<FileReader>): void => {
                const blob = e.target?.result as string;
                if (!blob) {
                    reject(new Error(t('widget.browseFile.emptyFile')));
                } else {
                    const rows = blob.split(SPLIT_LINES).map((row) => {
                        return splitDelimiter(row);
                    });
                    processRows(rows, reject, resolve);
                }
            };

            reader.onerror = (e: ProgressEvent<FileReader>): void => {
                reject(
                    new Error(
                        `${t('widget.browseFile.loadingError')}: ${importedFile.name} - ${e.target?.error?.name}`,
                    ),
                );
            };

            try {
                reader.readAsText(importedFile);
            } catch (e) {
                reject(new Error(e as string));
            }
        },
        [processRows, t],
    );

    const pFileReader = useCallback(
        (importedFile: File): Promise<BulkSubscription[]> => {
            return new Promise<BulkSubscription[]>((resolve, reject) => {
                if (importedFile.type === FileTypes.XLSX) {
                    readXlsxFile(importedFile).then((rows) => processRows(rows as string[][], reject, resolve));
                } else {
                    processCSV(importedFile, reject, resolve);
                }
            });
        },
        [processCSV, processRows],
    );

    useEffect(() => {
        if (!file) return;

        pFileReader(file)
            .then((fileResult) => {
                setResult(fileResult);
                setError(null);
            })
            .catch((err: Error) => {
                setResult([]);
                setError(err.message);
            });
    }, [file, pFileReader]);

    return { result, error };
};

export default useSubscriptionFileReader;
