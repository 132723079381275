import { BU_CODE } from 'constants/bu';
import { ECountry } from 'types';

export const getCountryFromUrl = (): ECountry => {
    const part = window.location.hostname.split('.').reverse()[3];
    const defaultCountry = (localStorage.getItem('BU') as ECountry) ?? ECountry.SE;
    return part ? (part.toUpperCase() as ECountry) : defaultCountry;
};

export const getBaseUrl = (): string => {
    const { protocol, hostname, port } = window.location;

    const urlProtocol = `${protocol}//`;
    const urlPort = port ? `:${port}` : '';

    return urlProtocol + hostname + urlPort;
};

export const getBusinessUnitCode = (): string => {
    return BU_CODE[getCountryFromUrl()];
};
