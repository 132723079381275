import { primaryMerBlue } from 'styles/variables';
import { useDebounce } from 'hooks/useDebounce';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import DeleteIcon from 'assets/icons/cross.svg?react';
import IconButton from 'components/clickables/IconButton';
import SearchIcon from 'assets/icons/search.svg?react';
import TextInput from './TextInput';
import styled from 'styled-components';

const StyledIconButton = styled(IconButton)`
    color: ${primaryMerBlue};
`;

const StyledTextInput = styled(TextInput)`
    margin: 0;
    max-width: 30rem;
    min-width: 12.5rem;
    input {
        margin: 0;
    }
`;

type Props = {
    placeholder: string;
};

export default function SearchInput({ placeholder }: Props): JSX.Element {
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchTerm, setSearchTerm] = useState(searchParams.get('searchTerm') ?? '');
    const searchDebounce = useDebounce(searchTerm, 500);

    useEffect(() => {
        setSearchTerm(searchParams.get('searchTerm') ?? '');
    }, [searchParams]);

    useEffect(() => {
        // logic to start after first 3 characters
        if (
            searchDebounce.length > 2 &&
            searchTerm &&
            searchTerm.length > 2 &&
            searchParams.get('searchTerm') !== searchTerm
        ) {
            const querySearchParam = searchParams;
            querySearchParam.set('searchTerm', searchDebounce);

            setSearchParams(querySearchParam);
        } else if (searchTerm.length === 0 && searchParams.get('searchTerm') !== searchTerm) {
            setSearchTerm('');

            const querySearchParam = searchParams;

            querySearchParam.delete('searchTerm');
            setSearchParams(querySearchParam);
        }
    }, [searchDebounce, searchParams, setSearchParams, searchTerm]);

    return (
        <StyledTextInput
            placeholder={placeholder}
            value={searchTerm}
            name="search"
            dataTestId="searchField"
            onChange={(e) => {
                setSearchTerm(e.target.value);
            }}
            StartIcon={SearchIcon}
            adornment={
                searchTerm?.length ? (
                    <StyledIconButton
                        onClick={() => {
                            setSearchTerm('');
                            const querySearchParam = searchParams;
                            querySearchParam.delete('searchTerm');
                            setSearchParams(querySearchParam);
                        }}
                    >
                        <DeleteIcon />
                    </StyledIconButton>
                ) : null
            }
        />
    );
}
