import { EBusinessFeature } from 'types/business';
import { createContext, useState } from 'react';
import { spaceS } from 'styles/variables';
import { useErrors } from 'contexts/ErrorContext';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ChargingHistory from 'components/subscriptions/statistics/ChargingHistory';
import DropdownInput from 'components/forms/DropdownInput';
import KPIPage from 'components/subscriptions/statistics/KPIPage';
import Page from 'components/layout/Page';
import SubscriptionStatisticsContext from 'contexts/SubscriptionStatisticsContext';
import Tabs, { Tab } from 'components/clickables/Tabs';
import styled from 'styled-components';
import useBusinesses from 'hooks/useBusinesses';
import useGetBusiness from 'hooks/useGetBusiness';

const StyledTabs = styled(Tabs)`
    label {
        min-width: 10rem;
    }
    margin-bottom: ${spaceS};
`;

const StyledDropdownInput = styled(DropdownInput)`
    max-width: 18.75rem;
    margin-bottom: 0;
`;

export const DropdownContext = createContext('organisationDropdownValue');

function SubscriptionStatistics(): JSX.Element {
    const { t } = useTranslation();
    const { errors } = useErrors();

    const tabsArray: Tab[] = [
        {
            text: t('subscription.statistics.kpiTitle'),
            value: 'KPI',
        },
        {
            text: t('subscription.statistics.chargingHistoryTitle'),
            value: 'CHARGING',
        },
    ];

    const [selectedTab, setSelectedTab] = useState<Tab>(tabsArray[0]);

    const { businesses } = useBusinesses();
    const { getBusinessesListByFeature } = useGetBusiness(businesses);

    const organisationList = getBusinessesListByFeature(
        [EBusinessFeature.SUBSCRIPTION_MANAGEMENT_EDIT, EBusinessFeature.SUBSCRIPTION_MANAGEMENT_VIEW],
        true,
    );

    const selectedOrganisation = organisationList[0].value;

    const { register, watch } = useForm({ defaultValues: { organisationDropdown: selectedOrganisation } });

    const selectedDropdownValue = watch('organisationDropdown');

    const OrganisationsDropDown =
        organisationList.length > 1 ? (
            <StyledDropdownInput
                dataTestId="organisationDropdown"
                options={organisationList.map((business) => ({
                    value: business.value,
                    text: business.text,
                }))}
                {...register('organisationDropdown')}
                name="organisationDropdown"
                defaultValue={selectedOrganisation}
                hidePlaceholderOption
                hideOptionalText
            />
        ) : undefined;

    return (
        <Page title={t('subscription.statistics.title')} errors={errors}>
            <StyledTabs tabs={tabsArray} activeTab={selectedTab} onTabClick={setSelectedTab} size="normal" />
            <SubscriptionStatisticsContext.Provider value={selectedDropdownValue.split(',')}>
                {selectedTab.value === tabsArray[0].value ? (
                    <KPIPage organisationsDropDown={OrganisationsDropDown} />
                ) : (
                    <ChargingHistory organisationsDropDown={OrganisationsDropDown} />
                )}
            </SubscriptionStatisticsContext.Provider>
        </Page>
    );
}

export default SubscriptionStatistics;
