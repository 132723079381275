import { EPageErrorType } from 'types';
import ErrorPage from './ErrorPage';
import PageLayoutWithoutMenu from 'components/layout/PageLayoutWithoutMenu';

function ErrorPageWithoutUserMenu(): JSX.Element {
    return (
        <PageLayoutWithoutMenu headerWithUserMenu={false}>
            <ErrorPage type={EPageErrorType.TEMPORARY} />
        </PageLayoutWithoutMenu>
    );
}

export default ErrorPageWithoutUserMenu;
