import { LoadingIndicator } from 'components/indicators/LoadingIndicator';
import {
    borderColorGrey,
    borderRadiusM,
    borderRadiusS,
    font,
    lightPink,
    lighterGrey,
    listHoverBackgroundColor,
    listHoverBorderColor,
    spaceS,
    white,
} from 'styles/variables';
import EmptyListMessage from 'components/info/EmptyListMessage';
import styled from 'styled-components';

const GridContainer = styled.section`
    border-radius: ${borderRadiusM};
    border: 1px solid ${borderColorGrey};
    overflow: hidden;
`;
const List = styled.ol<{ $gridColumns: string }>`
    list-style: none;
    overflow: hidden;

    li {
        display: grid;
        grid-template-columns: ${({ $gridColumns }) => $gridColumns};
        gap: ${spaceS};
        align-items: center;
    }
`;

export const GridListItem = styled.li`
    padding: 0 1.25rem;
    height: 3.5rem;
    background-color: ${white};
    border: 1px solid transparent;
    border-left: 0;
    border-right: 0;
    p {
        margin: 0;
    }
    &.invalid-row {
        background-color: ${lightPink};
    }
    &:not(:first-child) {
        border-top: 1px solid ${borderColorGrey};
    }
    &:nth-child(2) {
        border-top-color: transparent;
    }

    &:hover {
        background: ${listHoverBackgroundColor};
        border-top-color: ${listHoverBorderColor};
        &:not(:last-child) {
            border-bottom-color: ${listHoverBorderColor};
        }
    }
    &:hover + li {
        border-top-color: transparent;
    }
`;

export const GridListHeader = styled.li`
    font-weight: ${font.weight.bold};
    padding: 0 1.25rem;
    height: 3.5rem;
    background: ${lighterGrey};
    border-radius: ${borderRadiusS} ${borderRadiusS} 0 0;
`;

export const Header = styled.span`
    font-weight: ${font.weight.bold};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

type GridListProps = {
    children: React.ReactNode;
    gridColumns: string;
    dataTestId?: string;
    className?: string;
    showEmptyListMessage?: boolean;
    isLoading?: boolean;
};

function GridList({
    children,
    gridColumns,
    dataTestId,
    className,
    showEmptyListMessage,
    isLoading,
}: GridListProps): JSX.Element {
    return (
        <GridContainer>
            <List $gridColumns={gridColumns} data-testid={dataTestId} className={className}>
                {children}
            </List>
            {isLoading && <LoadingIndicator displayBorder={false} />}
            {showEmptyListMessage && <EmptyListMessage />}
        </GridContainer>
    );
}

export default GridList;
