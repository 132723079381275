import { EUsageType } from 'types/subscription';
import { getISOWeek, getMonth, getYear } from 'date-fns';
import { getUsageByType } from 'utils/subscription/chart';
import { useContext, useEffect, useState } from 'react';
import { useErrors } from 'contexts/ErrorContext';
import ConsumedEnergyChart from './ConsumedEnergyChart';
import KWhUsageByType from './KWhUsageByType';
import KpiList from './KpiList';
import MostUsedChargers from './MostUsedChargers';
import PartnerRoamingKpi from './PartnerRoamingKpi';
import SubscriptionStatisticsContext from 'contexts/SubscriptionStatisticsContext';
import TimeFrameFilter, { ETimeFrame } from './TimeFrameFilter';
import TotalKwhKpi from './TotalKwhKpi';
import useKpi from 'hooks/useKpi';

function DynamicKPISection(): JSX.Element {
    const [timeFrame, setTimeFrame] = useState('');
    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');
    const [week, setWeek] = useState('');
    const { setError } = useErrors();

    const organisationIds = useContext(SubscriptionStatisticsContext);

    const {
        kpiData,
        error: errorKpi,
        isLoading: isLoadingKpi,
    } = useKpi({ businessIds: organisationIds, year, month, week });

    useEffect(() => {
        if (setError && !isLoadingKpi && errorKpi) {
            setError(errorKpi);
        }
    }, [setError, errorKpi, isLoadingKpi]);

    const handleTimeFrameChange = (type: ETimeFrame, date: Date): void => {
        setYear(getYear(date).toString());
        setTimeFrame(type);
        if (type === ETimeFrame.MONTHLY) {
            const adjustedMonth = getMonth(date) + 1; // BFF expect month numbers 1-12, date-fns generate 0-11
            setMonth(adjustedMonth.toString());
            setWeek('');
        } else if (type === ETimeFrame.WEEKLY) {
            setWeek(getISOWeek(date).toString());
            setMonth('');
        } else {
            setWeek('');
            setMonth('');
        }
    };

    const locationData = kpiData?.consumedEnergyForLocations ?? [];

    return (
        <>
            <TimeFrameFilter onTimeFrameChange={handleTimeFrameChange} />
            <ConsumedEnergyChart
                isLoading={isLoadingKpi}
                data={kpiData?.consumedEnergyChartEntries}
                timeFrame={timeFrame}
            />
            <KWhUsageByType
                name={EUsageType.PUBLIC}
                kWh={getUsageByType(EUsageType.PUBLIC, locationData)}
                dataTestId="kwhUsagePublic"
            />
            <KWhUsageByType
                name={EUsageType.INTERNAL}
                kWh={getUsageByType(EUsageType.INTERNAL, locationData)}
                dataTestId="kwhUsageInternal"
            />
            <KWhUsageByType
                name={EUsageType.HOME}
                kWh={getUsageByType(EUsageType.HOME, locationData)}
                dataTestId="kwhUsageHome"
            />
            <TotalKwhKpi data={kpiData?.consumedEnergyForSubscriptionTypes ?? []} />
            <PartnerRoamingKpi data={kpiData?.consumedEnergyForRoamingPartners ?? []} />
            <KpiList data={kpiData} />
            <MostUsedChargers chargerPoints={kpiData?.usedChargePoints ?? []} />
        </>
    );
}

export default DynamicKPISection;
