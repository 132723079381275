import { ELocale, EToastType } from 'types';
import { ETrackingEvent } from 'types/tracking';
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import {
    borderRadiusS,
    font,
    headerHeight,
    iconSize,
    iconSizeS,
    lightBlue,
    menuBoxShadow,
    spaceS,
    spaceXs,
    white,
} from 'styles/variables';
import { useLanguage } from 'hooks/useLanguage';
import { useToast } from 'contexts/Toast';
import Arrow from 'assets/icons/chevron.svg?react';
import Checkmark from 'assets/icons/check.svg?react';
import ReactGA from 'react-ga4';
import i18n from 'i18n';
import styled from 'styled-components';

const Flag = styled.div`
    width: ${iconSize};
    height: ${iconSize};
    svg {
        width: inherit;
        height: inherit;
    }
`;

const Container = styled.div`
    margin-left: auto;
    position: relative;
    font-size: ${font.size.s};
    font-family: ${font.body};

    .select-button {
        height: ${headerHeight};
        width: 9rem;
        border: none;
        background: none;
        padding: 0 ${spaceS};
        display: grid;
        grid-template-columns: ${iconSize} 1fr ${iconSizeS};
        align-items: center;
        gap: ${spaceXs};
        text-align: left;
        font-family: inherit;
        font-size: inherit;

        &:hover {
            background: ${lightBlue};
        }
        .arrow {
            &.open {
                transform: rotate(180deg);
            }
        }
    }
    .select-dropdown {
        width: 11rem;
        border-radius: 0 0 ${borderRadiusS} ${borderRadiusS};
        box-shadow: ${menuBoxShadow};
        position: absolute;
        left: 0;
        top: calc(${headerHeight} + 1px);
        z-index: 1;
        overflow: hidden;
        background: ${white};
        padding: ${spaceXs} 0;

        .language {
            height: 2.5rem;
            &:hover {
                background: ${lightBlue};
            }
            button {
                font-family: inherit;
                font-size: inherit;
                padding: 0 ${spaceS};
                margin: 0;
                height: inherit;
                background: none;
                border: none;
                width: 100%;
                text-align: left;
                color: inherit;
                display: grid;
                grid-template-columns: ${iconSize} 1fr ${iconSizeS};
                align-items: center;
                gap: ${spaceXs};
            }
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
`;

const StyledCheckmark = styled(Checkmark)`
    height: ${iconSizeS};
    width: ${iconSizeS};
`;

function LanguageSelector(): JSX.Element {
    const domRef = useRef<HTMLDivElement>();
    const [dropDownOpen, setDropDownOpen] = useState(false);
    const { addToast } = useToast();

    const { getLanguageList, getValidLanguage } = useLanguage();
    const languagelist = useMemo(() => getLanguageList(), [getLanguageList]);

    const activeLanguage = getValidLanguage((localStorage.getItem('locale') as ELocale) ?? ELocale.EN);

    const handleChangeLanguage = (language: ELocale): void => {
        i18n.changeLanguage(language).catch((err) => addToast({ message: err, type: EToastType.ERROR }));
        setDropDownOpen(false);
        ReactGA.event(ETrackingEvent.CHANGE_LANGUAGE, {
            label: language,
        });
    };

    useEffect(() => {
        function handler(e: MouseEvent): void {
            if (!domRef.current?.contains(e.target as HTMLDivElement)) {
                setDropDownOpen(false);
            }
        }

        document.addEventListener('mousedown', handler);
        return () => {
            document.removeEventListener('mousedown', handler);
        };
    }, []);

    return (
        <Container ref={domRef as unknown as MutableRefObject<HTMLDivElement>}>
            <div className="dropdown">
                <button
                    data-testid="languageSelector"
                    type="button"
                    className="select-button"
                    onClick={() => setDropDownOpen(!dropDownOpen)}
                    role="combobox"
                    aria-haspopup="listbox"
                    aria-controls="language-dropdown"
                    aria-expanded={dropDownOpen}
                    aria-activedescendant={`language_${activeLanguage?.value}`}
                >
                    <Flag>{activeLanguage?.flag}</Flag> {activeLanguage?.name}
                    <Arrow className={`arrow ${dropDownOpen && 'open'}`} />
                </button>
                {dropDownOpen && (
                    <ul
                        className="select-dropdown"
                        role="listbox"
                        id="language-dropdown"
                        tabIndex={-1}
                        data-testid="languageList"
                    >
                        {languagelist.map(({ name, value, flag }) => (
                            <li
                                key={value}
                                data-testid={`language_${value}`}
                                id={`language-${value}`}
                                aria-selected={activeLanguage?.value === value}
                                role="option"
                                className="language"
                            >
                                <button type="button" onClick={() => handleChangeLanguage(value)}>
                                    <Flag>{flag}</Flag> <span>{name}</span>
                                    {activeLanguage?.value === value && <StyledCheckmark />}
                                </button>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </Container>
    );
}

export default LanguageSelector;
