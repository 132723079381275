import { ECurrency } from 'types';
import { useContext, useEffect, useMemo } from 'react';
import { useCountrySpecificContent } from 'hooks/useCountrySpecificContent';
import { useCurrency } from 'hooks/useCurrency';
import { useErrors } from 'contexts/ErrorContext';
import { useTranslation } from 'react-i18next';
import ApiError from 'classes/ApiError';
import Coins from 'assets/icons/coins_large.svg?react';
import KpiContainer from 'components/kpi/KpiContainer';
import KpiContent from 'components/kpi/KpiContent';
import KpiListItem, { KpiList } from 'components/kpi/KpiListItem';
import Lightbulb from 'assets/illustrations/lightbulb.svg?react';
import PopUp from 'components/info/PopUp';
import SubscriptionStatisticsContext from 'contexts/SubscriptionStatisticsContext';
import TooltipContentComponent from 'components/info/TooltipContent';
import useSubscriptionsTotalCost from 'hooks/useSubscriptionsTotalCost';

function TotalCostKpi(): JSX.Element {
    const { t } = useTranslation();
    const { setErrors } = useErrors();
    const organisationIds = useContext(SubscriptionStatisticsContext);
    const { currency } = useCountrySpecificContent();
    const { toCurrency } = useCurrency();

    const {
        totalCosts,
        error: errorSubscriptionsTotalCost,
        isLoading: isLoadingSubscriptionsTotalCost,
    } = useSubscriptionsTotalCost({
        businessIds: organisationIds,
    });

    const totalCostForBUCurrency = useMemo(
        () =>
            totalCosts?.find((cost) => cost.currency === currency) ?? {
                fixedCost: 0,
                variableCost: 0,
                totalCost: 0,
            },
        [totalCosts, currency],
    );

    const totalAdditionalCosts = totalCosts?.filter((cost) => cost.currency !== currency);

    useEffect(() => {
        if (setErrors) {
            const errs: ApiError[] = [];
            if (!isLoadingSubscriptionsTotalCost && errorSubscriptionsTotalCost) {
                errs.push(errorSubscriptionsTotalCost);
            }
            if (errs.length > 0) {
                setErrors(errs);
            }
        }
    }, [setErrors, isLoadingSubscriptionsTotalCost, errorSubscriptionsTotalCost]);

    const total = toCurrency({
        amount: totalCostForBUCurrency.totalCost,
        currency,
    });

    const haveCostsInOtherCurrencies = totalAdditionalCosts && totalAdditionalCosts.length > 0;

    return (
        <KpiContainer
            dataTestId="totalCostKpi"
            title={t('subscription.statistics.kpi.totalCost.title')}
            subtitle={t('subscription.statistics.kpi.totalCost.subtitle')}
            tooltip={
                haveCostsInOtherCurrencies && (
                    <PopUp
                        dataTestId="totalCostInfo"
                        content={
                            <TooltipContentComponent
                                headline={t('subscription.statistics.kpi.totalCost.infoBoxHeadline')}
                                text={t('subscription.statistics.kpi.totalCost.infoBoxText')}
                                icon={<Lightbulb />}
                            />
                        }
                    />
                )
            }
            content={
                <>
                    <KpiContent icon={<Coins />} text={total} />
                    <KpiList>
                        <KpiListItem
                            title={t('subscription.statistics.kpi.totalCost.viableCost')}
                            value={toCurrency({ amount: totalCostForBUCurrency.variableCost, currency })}
                        />
                        <KpiListItem
                            title={t('subscription.statistics.kpi.totalCost.fixedFee')}
                            value={toCurrency({ amount: totalCostForBUCurrency.fixedCost, currency })}
                        />

                        {haveCostsInOtherCurrencies && (
                            <KpiListItem
                                dataTestId="additionalCosts"
                                title={t('subscription.statistics.kpi.totalCost.additionalCost')}
                                value={totalAdditionalCosts.map((cost) =>
                                    toCurrency({
                                        amount: cost.variableCost,
                                        currency: cost.currency as ECurrency,
                                    }),
                                )}
                            />
                        )}
                    </KpiList>
                </>
            }
        />
    );
}

export default TotalCostKpi;
