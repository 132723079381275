import { borderRadiusS, lightBlue, lighterBlue, mediumGrey } from 'styles/variables';
import styled from 'styled-components';

const IconButton = styled.button`
    background: transparent;
    border: none;
    border-radius: ${borderRadiusS};
    cursor: pointer;
    line-height: 0;
    width: 2rem;
    height: 2rem;
    &:hover:not(:disabled) {
        background: ${lighterBlue};
    }

    &:active {
        background: ${lightBlue};
    }
    &:disabled {
        color: ${mediumGrey};
        svg {
            fill: ${mediumGrey};
        }
    }
`;

export default IconButton;
