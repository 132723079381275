import { EToastType } from 'types';
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ToastType } from 'components/info/ToastMessage';

type Props = {
    children: ReactNode;
};

export type ToastContextType = {
    addToast: (v: Partial<ToastType>) => void;
    removeToast: (v: number) => void;
    toasts: ToastType[];
};

export const ToastContext = createContext<ToastContextType>({
    addToast: () => undefined,
    removeToast: () => undefined,
    toasts: [],
});

let toastId = 0;

export function ToastProvider(props: Props): JSX.Element {
    const [toasts, setToasts] = useState<ToastType[]>([]);

    useEffect(() => {
        if (toasts.length === 0) {
            toastId = 0;
        }
    }, [toasts.length]);

    const addToast = useCallback(
        (content: Partial<ToastType>) => {
            toastId += 1;
            const newToast = {
                id: toastId,
                message: content.message || '',
                fadeTime: content.fadeTime,
                removeTime: content.removeTime,
                type: content.type || EToastType.SUCCESS,
            };
            setToasts((prevState) => [newToast, ...prevState]);
        },
        [setToasts],
    );

    const removeToast = useCallback(
        (id: number) => {
            setToasts((currentState) => currentState.filter((t) => t.id !== id));
        },
        [setToasts],
    );

    const context = useMemo(() => ({ addToast, removeToast, toasts }), [addToast, removeToast, toasts]);

    return <ToastContext.Provider value={context} {...props} />;
}

export const useToast = (): ToastContextType => useContext(ToastContext);
