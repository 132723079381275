import { useTranslation } from 'react-i18next';
import Lightbulb from 'assets/illustrations/lightbulb.svg?react';
import PopUp from 'components/info/PopUp';
import TooltipContentComponent from 'components/info/TooltipContent';

function DeliveryOptionsInfo(): JSX.Element {
    const { t } = useTranslation();
    return (
        <PopUp
            dataTestId="deliveryOptionsInfoButton"
            content={
                <TooltipContentComponent
                    headline={t('form.input.deliveryOption.label')}
                    text={t('form.input.deliveryOption.info')}
                    icon={<Lightbulb data-testid="bulbIcon" />}
                />
            }
        />
    );
}

export default DeliveryOptionsInfo;
