export enum FEATURE_LIST {
    FACILITY_MANAGEMENT = 'FACILITY_MANAGEMENT',
    SHOW_ONBOARD_GUIDE = 'SHOW_ONBOARD_GUIDE',
}

export function showFeature(feature: FEATURE_LIST): boolean {
    const FEATURE_TOGGLE = {
        FACILITY_MANAGEMENT: import.meta.env.VITE_APP_SHOW_FACILITY_MANAGEMENT === 'true',
        SHOW_ONBOARD_GUIDE: import.meta.env.VITE_APP_SHOW_ONBOARD_GUIDE === 'true',
    };

    return FEATURE_TOGGLE[feature];
}
