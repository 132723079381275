import { font, spaceS } from 'styles/variables';
import styled from 'styled-components';

const Container = styled.section`
    margin-bottom: 2.5rem;
`;

const SectionTitle = styled.h2`
    font-size: ${font.size.l};
    line-height: ${font.lineHeight.l};
    margin-bottom: ${spaceS};
`;

type SectionContainerProps = {
    title: string;
    children: React.ReactNode;
    className?: string;
    dataTestId?: string;
};

function SectionContainer({ title, children, className, dataTestId }: SectionContainerProps): JSX.Element {
    return (
        <Container className={className} data-testid={dataTestId}>
            <SectionTitle>{title}</SectionTitle>
            {children}
        </Container>
    );
}

export default SectionContainer;
