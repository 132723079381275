import { useMemo } from 'react';
import AppRoutes from 'AppRoutes';
import FullPageLoadingIndicator from 'components/indicators/FullPageLoadingIndicator';
import PageWithoutRole from 'pages/PageWithoutRole';
import useBusinesses from 'hooks/useBusinesses';
import useGetBusiness from 'hooks/useGetBusiness';

function RouteContentWrapper(): JSX.Element {
    const { businesses, isLoading } = useBusinesses();
    const { getBusiness } = useGetBusiness(businesses ?? []);

    const activeBusiness = getBusiness(localStorage.getItem('activeParentBusinessId') ?? '');

    const hasFeatures = useMemo(() => {
        if (!isLoading && businesses) {
            let hasAnyFeatures = false;
            businesses.forEach((business) => {
                if (business.features?.length && business.features?.length > 0) {
                    hasAnyFeatures = true;
                }
                business.subsidiaries?.forEach((sub) => {
                    if (sub.features?.length && sub.features?.length > 0) {
                        hasAnyFeatures = true;
                    }
                });
            });
            return hasAnyFeatures;
        }
        return false;
    }, [businesses, isLoading]);

    if (
        businesses &&
        hasFeatures &&
        !isLoading &&
        (!localStorage.getItem('activeParentBusinessId') || !activeBusiness)
    ) {
        localStorage.setItem('activeParentBusinessId', businesses[0].id);
    }

    if (!isLoading && !hasFeatures) {
        return <PageWithoutRole />;
    }
    if (isLoading) {
        return <FullPageLoadingIndicator />;
    }
    return <AppRoutes />;
}

export default RouteContentWrapper;
