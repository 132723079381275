import { BulkSubscriptionType, IBulkSubscriptionType } from 'types/subscription';
import { ECountry, EDeliveryOption } from 'types';

export const sanitizeBulkSubscriptionOrders = (
    subscriptionOrders: IBulkSubscriptionType[],
    countryCode: ECountry,
): BulkSubscriptionType[] => {
    const sanitizedSubscriptions = [...subscriptionOrders].map((order) => {
        const orderObject = {
            row: order.row,
            businessId: order.organisation?.id,
            ...(!order.unknownContact && {
                contact: {
                    email: order.contact?.email,
                    firstName: order.contact?.firstName,
                    lastName: order.contact?.lastName,
                    countryCode,
                },
            }),
            productName: order.type,
            licencePlate: order.licencePlate,
            reference: order.reference,
            deliveryOption: order.deliveryOption,
            countryCode,
            shipToPostalAddressId:
                order.deliveryOption === EDeliveryOption.MAIN ? order.shipToPostalAddressId : undefined,
        };

        return orderObject;
    });

    return sanitizedSubscriptions as BulkSubscriptionType[];
};
