import { EConnectorStatus, IConnector, IConnectorStatus, IProperty } from 'types/facility';

export const getSitesFromPropertyList = (propertyList: IProperty[]): string[] => {
    return propertyList.flatMap((prop) => prop.sites.map((site) => site.id));
};

export const batchArray = <T>(list: T[], batchSize: number): T[][] => {
    const batches = [];
    for (let i = 0; i < list.length; i += batchSize) {
        const batch = list.slice(i, i + batchSize);
        batches.push(batch);
    }
    return batches;
};

export const getConnectorStatus = (connectorState: IConnectorStatus[], evseId: string): IConnectorStatus => {
    if (!connectorState) {
        return { evseId, status: EConnectorStatus.UNKNOWN };
    }
    return connectorState.find((prop) => prop.evseId === evseId) ?? { evseId, status: EConnectorStatus.UNKNOWN };
};

export const getFilteredConnectors = (propertyList: IProperty[]): IConnector[] => {
    return propertyList.flatMap((property) =>
        property.sites.flatMap((site) => site.chargers.flatMap((chargers) => chargers.connectors)),
    );
};

export const getStatusesForConnectors = (
    connectors: IConnector[],
    connectorStates: IConnectorStatus[],
): IConnectorStatus[] => {
    return connectors.map((connector) => {
        return getConnectorStatus(connectorStates, connector.evseId);
    });
};

export const getAmountPerType = (
    propertyList: IProperty[],
    connectorStates: IConnectorStatus[],
    type: EConnectorStatus,
): number => {
    return getStatusesForConnectors(getFilteredConnectors(propertyList), connectorStates).filter(
        (prop) => prop.status === type,
    ).length;
};
