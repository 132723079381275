import { AccountInfo, EndSessionRequest, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { getBaseUrl, getBusinessUnitCode } from 'utils/url';
import { useMsal } from '@azure/msal-react';
import useBroadcast from './useBroadcast';

export const signupRequest: RedirectRequest = {
    scopes: import.meta.env.VITE_APP_AZUREAD_SCOPES!.split(','),
    authority: import.meta.env.VITE_APP_AZUREAD_AUTHORITY!,
    prompt: 'login',
    extraQueryParameters: {
        entry_point: 'signup',
    },
};

export const regularRequest: RedirectRequest = {
    scopes: import.meta.env.VITE_APP_AZUREAD_SCOPES!.split(','),
    authority: import.meta.env.VITE_APP_AZUREAD_AUTHORITY!,
};

export const changeEmailRequest: RedirectRequest = {
    scopes: import.meta.env.VITE_APP_AZUREAD_SCOPES!.split(','),
    authority: import.meta.env.VITE_APP_AZUREAD_AUTHORITY_CHANGEEMAIL!,
};

export const pca = new PublicClientApplication({
    auth: {
        clientId: import.meta.env.VITE_APP_AZUREAD_CLIENT_ID!,
        knownAuthorities: import.meta.env.VITE_APP_AZUREAD_KNOWN_AUTHORITIES!.split(' '),
        redirectUri: getBaseUrl(),
    },
});

type useAuthenticationResult = {
    acquireAccessTokenSilent: () => Promise<string>;
    changeEmailRedirect: () => void;
    existsAccount: () => boolean;
    getAccount: () => Partial<AccountInfo> | undefined;
    getAccountContactId: () => string;
    getAccountEmail: () => string;
    getAccountFirstAndLastName: () => {
        firstName: string;
        lastName: string;
    };
    getAccountName: () => string;
    getAccountNameInitials: () => string;
    loginRedirectRegular: () => void;
    logoutRedirectRegular: (goHome?: boolean) => void;
    registerLogoutCallback: () => void;
    signupRedirect: (email: string) => void;
};

const useAuthentication: () => useAuthenticationResult = () => {
    const { instance } = useMsal();
    const { close, registerOnMessage, postMessage } = useBroadcast();

    const registerLogoutCallback: () => void = () => {
        registerOnMessage((msg) => {
            if (msg?.data === 'logout') {
                instance.logout();
                close();
            }
        });
    };

    const existsAccount: () => boolean = () => !!instance.getAllAccounts()?.length;

    const getAccount: () => AccountInfo | undefined = () => instance.getAllAccounts()?.[0];

    const getAccountEmail: () => string = () => {
        return getAccount()?.idTokenClaims?.email as string;
    };

    const getAccountContactId: () => string = () => {
        return getAccount()?.idTokenClaims?.contactId as string;
    };

    const getAccountFirstAndLastName: () => {
        firstName: string;
        lastName: string;
    } = () => {
        const account = getAccount();
        if (!account) {
            return { firstName: '', lastName: '' };
        }

        return {
            firstName: account.idTokenClaims?.firstName as string,
            lastName: account.idTokenClaims?.lastName as string,
        };
    };

    const getAccountName: () => string = () => {
        const { firstName, lastName } = getAccountFirstAndLastName();
        return `${firstName} ${lastName}`.trim();
    };

    const getAccountNameInitials: () => string = () => {
        const { firstName, lastName } = getAccountFirstAndLastName();
        const firstInitial = firstName?.charAt(0)?.toUpperCase() || '';
        const lastInitial = lastName?.charAt(0)?.toUpperCase() || '';

        return firstInitial + lastInitial;
    };

    const logoutRedirectRegular: (goHome?: boolean) => void = (goHome) => {
        const logoutRequest: EndSessionRequest = {
            account: getAccount(),
            authority: import.meta.env.VITE_APP_AZUREAD_AUTHORITY!,
            postLogoutRedirectUri: goHome ? getBaseUrl() : window.location.toString(),
        };
        localStorage.removeItem('activeParentBusinessId');

        postMessage('logout');
        instance
            .logoutRedirect(logoutRequest)
            // eslint-disable-next-line no-console
            .catch((e) => console.error('Something went wrong in useAuthentication - logoutRedirectRegular:', e));
    };

    const signupRedirect: (email: string) => void = (email) => {
        instance.loginRedirect({
            ...signupRequest,
            extraQueryParameters: {
                ...signupRequest.extraQueryParameters,
                login_hint: encodeURIComponent(email),
                company_code: getBusinessUnitCode(), // AzureAD takes care of it regardless of what we get from our URL
            },
            redirectStartPage: getBaseUrl(),
        });
    };

    const loginRedirectRegular: () => void = () => {
        instance
            .loginRedirect({
                ...regularRequest,
                extraQueryParameters: {
                    company_code: getBusinessUnitCode(), // AzureAD takes care of it regardless of what we get from our URL
                },
            })
            // eslint-disable-next-line no-console
            .catch((e) => console.error('Something went wrong in useAuthentication - loginRedirectRegular:', e));
    };

    const changeEmailRedirect: () => void = () => {
        instance.acquireTokenRedirect({
            ...changeEmailRequest,
            loginHint: getAccountEmail(),
            extraQueryParameters: {
                company_code: getBusinessUnitCode(), // AzureAD takes care of it regardless of what we get from our URL
            },
        });
    };

    const acquireAccessTokenSilent: () => Promise<string> = async () => {
        return (
            await instance.acquireTokenSilent({
                ...regularRequest,
                account: getAccount(),
            })
        ).accessToken;
    };

    return {
        acquireAccessTokenSilent,
        changeEmailRedirect,
        existsAccount,
        getAccount,
        getAccountContactId,
        getAccountEmail,
        getAccountFirstAndLastName,
        getAccountName,
        getAccountNameInitials,
        loginRedirectRegular,
        logoutRedirectRegular,
        registerLogoutCallback,
        signupRedirect,
    };
};

export default useAuthentication;
