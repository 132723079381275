import { ELocale } from 'types';
import { t } from 'i18next';

export const formatDate = (date?: string | Date, localeCode = ELocale.SV, shouldDisplayTime = true): string => {
    if (date) {
        try {
            const newDate = new Date(date);
            const localeDate = new Intl.DateTimeFormat(localeCode, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                ...(shouldDisplayTime && { hour: 'numeric', minute: 'numeric' }),
            }).format(newDate);
            return localeDate;
        } catch (error) {
            if (error instanceof RangeError) {
                return t('general.invalidDate');
            }
        }
    }
    return '';
};

export const autoAddSeparator = (dateString: string, localeCode = ELocale.SV): string => {
    let date = dateString;
    // Sweden
    if (localeCode === ELocale.SV && (date.length === 4 || date.length === 7)) {
        date += '-';
    }
    // Germany
    if (localeCode === ELocale.DE && (date.length === 2 || date.length === 5)) {
        date += '.';
    }
    return date;
};

export const shouldRemoveSeparator = (dateString: string, localeCode = ELocale.SV): boolean => {
    if (localeCode === ELocale.DE) {
        return dateString.length === 3 || dateString.length === 6;
    }

    return dateString.length === 5 || dateString.length === 8;
};
