import { borderRadiusS, errorColor, font, red, spaceS, spaceXxs, successColor } from 'styles/variables';
import { useTranslation } from 'react-i18next';
import DeleteIcon from 'assets/icons/delete.svg?react';
import IconButton from 'components/clickables/IconButton';
import styled from 'styled-components';

const Field = styled.div<{ $borderColor: string }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid;
    gap: ${spaceS};
    border-color: ${(prop) => prop.$borderColor};
    border-radius: ${borderRadiusS};
    padding: ${spaceXxs} ${spaceXxs} ${spaceXxs} ${spaceS};
`;

const StyledIconButton = styled(IconButton)`
    border-radius: 100%;
    &.green {
        color: ${successColor};
    }
    &.red {
        color: ${red};
    }
`;

const FileName = styled.p`
    font-size: ${font.size.xs};
    margin: 0;
`;

const ErrorMessage = styled.p`
    font-size: ${font.size.s};
    color: ${red};
    margin: 0;
`;

type FileValidationProp = {
    errorMessage?: string;
    fileName?: string;
    handleClick: () => void;
};

function FileValidationField({ errorMessage, fileName, handleClick }: FileValidationProp): JSX.Element {
    const { t } = useTranslation();
    return (
        <>
            <Field data-testid="validationField" $borderColor={errorMessage ? errorColor : successColor}>
                <FileName>{fileName}</FileName>
                <StyledIconButton
                    onClick={handleClick}
                    className={errorMessage ? 'red' : 'green'}
                    aria-label={t('general.delete')}
                >
                    <DeleteIcon />
                </StyledIconButton>
            </Field>
            <ErrorMessage>{errorMessage}</ErrorMessage>
        </>
    );
}

export default FileValidationField;
