import { ButtonGroupStyle } from 'styles/mixins';
import { add, compareAsc, format, getISOWeek, getYear, isSameISOWeek, isSameMonth, isSameYear, sub } from 'date-fns';
import { font } from 'styles/variables';
import { getDateLocale } from 'utils/dateLocalization';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LeftArrow from 'assets/icons/arrow_left.svg?react';
import RightArrow from 'assets/icons/arrow_right.svg?react';
import styled from 'styled-components';

const TimeFrameContainer = styled.section`
    grid-column: span 3;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 32px;
    margin-bottom: 8px;
`;
const TimeFrameButtons = styled.div`
    display: flex;
    align-items: center;
    button {
        ${ButtonGroupStyle}
    }
`;

const SelectedTimeText = styled.p`
    margin: 0;
    font-size: ${font.size.l};
    font-weight: ${font.weight.semiBold};
    flex-basis: 100%;
    border-top: 1px solid #f2f2f2;
    padding-top: 24px;
`;

const TimeFrame = styled.section`
    label {
        ${ButtonGroupStyle}
        display: inline-flex;
        align-items: center;
    }

    input {
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }
`;

export enum ETimeFrame {
    WEEKLY = 'WEEKLY',
    MONTHLY = 'MONTHLY',
    YEARLY = 'YEARLY',
}

type TimeFrameFilterProps = {
    onTimeFrameChange: (timeFrame: ETimeFrame, date: Date) => void;
};

function TimeFrameFilter({ onTimeFrameChange }: TimeFrameFilterProps): JSX.Element {
    const { t } = useTranslation();
    const now = useMemo(() => {
        return new Date();
    }, []);

    const [selectedTimeFrame, setSelectedTimeFrame] = useState(ETimeFrame.WEEKLY);
    const [date, setDate] = useState<Date>(now);
    const [timeFrameText, setTimeFrameText] = useState<string>('');

    useEffect(() => {
        onTimeFrameChange(selectedTimeFrame, date);
    }, [date, onTimeFrameChange, selectedTimeFrame]);

    const onLeftArrowClick = (): void => {
        if (selectedTimeFrame === ETimeFrame.WEEKLY) {
            setDate(sub(date, { weeks: 1 }));
        } else if (selectedTimeFrame === ETimeFrame.MONTHLY) {
            setDate(sub(date, { months: 1 }));
        } else {
            setDate(sub(date, { years: 1 }));
        }
    };

    const onRightArrowClick = (): void => {
        if (selectedTimeFrame === ETimeFrame.WEEKLY) {
            setDate(add(date, { weeks: 1 }));
        } else if (selectedTimeFrame === ETimeFrame.MONTHLY) {
            setDate(add(date, { months: 1 }));
        } else {
            setDate(add(date, { years: 1 }));
        }
    };

    useEffect(() => {
        if (selectedTimeFrame === ETimeFrame.WEEKLY) {
            setTimeFrameText(`${t('timeFrameFilter.week')} ${getISOWeek(date)} / ${getYear(date)}`);
        } else if (selectedTimeFrame === ETimeFrame.MONTHLY) {
            setTimeFrameText(` ${format(date, 'LLLL', { locale: getDateLocale() })} ${getYear(date)}`);
        } else {
            setTimeFrameText(`${getYear(date)}`);
        }
        // When going back a year and choosing a month bigger then today's date and we go back to current year, we need to reset to today's date
        // Otherwise the user can go in the future with the right arrow
        if (compareAsc(date, now) === 1) {
            setDate(now);
        }
    }, [date, t, now, selectedTimeFrame]);

    const disableButton = (): boolean => {
        // If date is same week as today's button disabled
        if (selectedTimeFrame === ETimeFrame.WEEKLY && isSameISOWeek(date, now)) {
            return true;
        }
        // If date is same month as today's button disabled
        if (selectedTimeFrame === ETimeFrame.MONTHLY && isSameMonth(date, now)) {
            return true;
        }
        // If date is same year as today's button disabled
        return selectedTimeFrame === ETimeFrame.YEARLY && isSameYear(date, now);
    };

    return (
        <TimeFrameContainer>
            <TimeFrame>
                <label htmlFor="timeFrameWeek" className={selectedTimeFrame === ETimeFrame.WEEKLY ? 'checked' : ''}>
                    <input
                        type="radio"
                        name="timeFrame"
                        value={ETimeFrame.WEEKLY}
                        id="timeFrameWeek"
                        data-testid="timeFrameWeek"
                        checked={selectedTimeFrame === ETimeFrame.WEEKLY}
                        onChange={() => setSelectedTimeFrame(ETimeFrame.WEEKLY)}
                    />
                    {t('timeFrameFilter.weekly')}
                </label>
                <label htmlFor="timeFrameMonth" className={selectedTimeFrame === ETimeFrame.MONTHLY ? 'checked' : ''}>
                    <input
                        type="radio"
                        name="timeFrame"
                        value={ETimeFrame.MONTHLY}
                        id="timeFrameMonth"
                        data-testid="timeFrameMonth"
                        checked={selectedTimeFrame === ETimeFrame.MONTHLY}
                        onChange={() => setSelectedTimeFrame(ETimeFrame.MONTHLY)}
                    />
                    {t('timeFrameFilter.monthly')}
                </label>
                <label htmlFor="timeFrameYear" className={selectedTimeFrame === ETimeFrame.YEARLY ? 'checked' : ''}>
                    <input
                        type="radio"
                        name="timeFrame"
                        value={ETimeFrame.YEARLY}
                        id="timeFrameYear"
                        data-testid="timeFrameYear"
                        checked={selectedTimeFrame === ETimeFrame.YEARLY}
                        onChange={() => setSelectedTimeFrame(ETimeFrame.YEARLY)}
                    />
                    {t('timeFrameFilter.yearly')}
                </label>
            </TimeFrame>
            <TimeFrameButtons>
                <button type="button" data-testid="previous" onClick={onLeftArrowClick} aria-label="previous">
                    <LeftArrow />
                </button>
                <button type="button" data-testid="showCurrent" onClick={() => setDate(now)}>
                    {t('timeFrameFilter.current')}
                </button>
                <button
                    type="button"
                    data-testid="next"
                    disabled={disableButton()}
                    onClick={onRightArrowClick}
                    aria-label="next"
                >
                    <RightArrow />
                </button>
            </TimeFrameButtons>
            <SelectedTimeText data-testid="currentSelection">{timeFrameText}</SelectedTimeText>
        </TimeFrameContainer>
    );
}

export default TimeFrameFilter;
