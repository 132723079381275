export default class ApiError extends Error {
    temporary?: boolean;

    status?: number;

    constructor(status?: number, temporary?: boolean) {
        super(`ApiError, status=${status}, temporary=${temporary}`);
        this.status = status;
        this.temporary = temporary;
    }
}
