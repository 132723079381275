import { ECurrency } from 'types';
import { useCountrySpecificContent } from './useCountrySpecificContent';

type ToCurrencyProps = {
    amount: number;
    currency: ECurrency;
    fractionDigits?: number;
};

type UseCurrencyReturnPorps = {
    toCurrency: ({ amount, currency }: ToCurrencyProps) => string;
};

export const useCurrency = (): UseCurrencyReturnPorps => {
    const { locale } = useCountrySpecificContent();

    const toCurrency = ({ amount, currency, fractionDigits }: ToCurrencyProps): string => {
        const hasDecimals = !!((amount || 0) % 1);
        const shouldShowDecimals = fractionDigits ?? hasDecimals;
        const decimals = shouldShowDecimals ? 2 : 0;

        return new Intl.NumberFormat(locale, {
            style: 'currency',
            currency,
            maximumFractionDigits: decimals,
        }).format(amount || 0);
    };

    return { toCurrency };
};
