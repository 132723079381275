import Tooltip from './Tooltip';
import styled from 'styled-components';

export const TextEllipsis = styled.p`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
`;

type TextEllipsisProps = {
    children?: string;
    tooltipText?: string;
    dataTestId?: string;
};

function TextEllipsisComponent({ children, tooltipText, dataTestId }: TextEllipsisProps): JSX.Element {
    const sanitizedText = children?.trim();
    const text = !sanitizedText || sanitizedText.includes('undefined') ? '-' : sanitizedText;
    const hideToolip = text === '-' || !tooltipText;

    if (hideToolip) {
        return <TextEllipsis data-testid={dataTestId}>{text}</TextEllipsis>;
    }

    return (
        <Tooltip text={tooltipText} data-testId="tooooool">
            <TextEllipsis data-testid={dataTestId}>{text}</TextEllipsis>
        </Tooltip>
    );
}

export default TextEllipsisComponent;
