import { BlobFile, EToastType } from 'types';
import { useCallback, useEffect, useState } from 'react';
import { useToast } from 'contexts/Toast';
import { useTranslation } from 'react-i18next';
import ApiError from 'classes/ApiError';
import saveAs from 'file-saver';

type UseExportReturnProps = {
    startExport: (getExport: () => Promise<BlobFile>, dataExists?: boolean) => void;
    somethingToExport?: boolean;
};

export const useExport = (content?: boolean): UseExportReturnProps => {
    const [somethingToExport, setSomethingToExport] = useState<boolean>(true);
    const { addToast } = useToast();
    const { t } = useTranslation();

    const startExport = useCallback(
        async (getExport: () => Promise<BlobFile>, dataExists?: boolean) => {
            try {
                if (dataExists) {
                    await getExport().then((exportBlob) => {
                        saveAs(exportBlob.file, exportBlob.fileName);
                    });
                } else {
                    setSomethingToExport(Boolean(dataExists));
                    addToast({ message: t('general.export.noData'), type: EToastType.ERROR });
                }
            } catch (e) {
                if (e instanceof ApiError) {
                    if (e.temporary) {
                        addToast({ message: t('general.errorToast'), type: EToastType.ERROR });
                    } else {
                        addToast({ message: t('general.export.error'), type: EToastType.ERROR });
                    }
                }
            }
        },

        [addToast, t],
    );

    useEffect(() => {
        if (content) {
            setSomethingToExport(true);
        }
    }, [setSomethingToExport, content]);

    return { startExport, somethingToExport };
};
